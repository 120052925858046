import React from "react";
import ContactUsForm from "./ContactUsForm";
import AboutHero from "./AboutHero";
import RadialGreenGradient from "../../ReusableComponents/RadialGreenGradiant";
import TeamInfo from "./TeamInfo";

const AboutComponent = () => {
  return (
    <div>
       <RadialGreenGradient className={"top-[5%] left-0"} style={{ backgroundImage:
          "radial-gradient(circle 9rem at 0% 50%, rgba(8, 184, 131, 0.4), #0000)",}}/> 
      <AboutHero />
      <RadialGreenGradient className={"top-[24%] right-0"} style={{ backgroundImage:
          "radial-gradient(circle 8rem at 100% 50%, rgba(8, 184, 131, 0.4), #0000)",}}/> 
      <TeamInfo/>
      <ContactUsForm />
    </div>
  );
};

export default AboutComponent;
