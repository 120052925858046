import React from 'react'
import ServiceHero from './ServiceHero'
import { servicesData } from '../../../Constants/ServicePageConstants'

const PlatformServices = () => {
  return (
    <ServiceHero serviceObj={servicesData?.find((item) => item?.id === "platform-services")} />
  )
}

export default PlatformServices
