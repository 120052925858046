import React from "react";
import { valuedByCompaniesData, useGSAP , gsap, ScrollTrigger } from "../../../Constants";
import { styles } from "../../../styles";
import Marquee from "react-fast-marquee";
gsap.registerPlugin(ScrollTrigger);

const ValuedBy = () => {

  useGSAP(() => {
    gsap.fromTo(
      "#valuedByTitle",
      {
        scale: 0,
      },
      {
        ease: "power2.inOut",
        scale: 1,
        duration: 1,
        scrollTrigger: {
          trigger: "#valuedByTitle",
        },
      }
    );
    gsap.fromTo("#categoryList", 
    {
      opacity: 0,
      x: -100,
    },
    {
      duration: 1,
      ease: "power1.inOut",
      opacity: 1,
      x: 0,
      stagger:{
        amount: 1,
        axis : "x",
        from : "start"
      },
      scrollTrigger: {
        trigger: "#valuedByTitle",
      },
    }
    );
    gsap.fromTo("#subCategoryImage", 
    {
      opacity: 0,
      x: -100,
    },
    {
      duration: 1,
      ease: "power1.inOut",
      delay: 1,
      opacity: 1,
      x: 0,
      stagger:{
        amount: 1,
        axis : "x",
        from : "start"
      },
      scrollTrigger: {
        trigger: "#valuedByTitle",
      },
    }
    )
  }, []);
  
  const valued_by_marquee_list = valuedByCompaniesData?.map((category, id) => (
    <div key={id} className={`${styles.flexColNWJSIS} p-4`} id="categoryList" >
      <p className="uppercase text-[1.2rem] text-black font-medium mb-2">
        {category?.categoryTitle}
      </p>
      <div
        className={`${styles.flexRowWJSIC} border-black border-[0.15rem] rounded-[1rem]`}
      >
        {category?.companies?.map((compnay, index) => (
          <img
           id="#subCategoryImage"
            key={index}
            alt={compnay?.company_id}
            src={compnay?.company_logo}
            className={`${
              id === 0 ? "mx-9" : id === 4 || id === 5 ? "mx-8" : "mx-7"
            } my-[1rem] h-[4rem] w-[7rem] `}
          />
        ))}
      </div>
    </div>
  ));
  return (
    <div
      className={`${styles.flexColNWJCIC}`}
      // style={{
      //   backgroundColor: "rgba(77, 172, 157, 0.1)",
      //   backdropFilter: "blur(10px)",
      //   boxShadow: "0 0px 40px rgba(77, 172, 157, 0.2)",
      //   border: "none",
      // }}
    >
      <p
       id="valuedByTitle"
        className={`${styles.sectionHeadText} text-center ${styles.marginTop}`}
      >
        VALUED BY
      </p>
      <div className={`w-full my-2 flex justify-center items-center `}>
        <div className="hidden md:flex  flex-wrap justify-center items-center xl:max-w-[55rem] 2xl:max-w-[60rem] ">
          {valued_by_marquee_list?.map((list, index) => (
            <div key={index}>{list}</div>
          ))}
        </div>
      </div>
      <div className={`w-full my-2 flex md:hidden `}>
        <Marquee
          autoFill
          pauseOnHover
          gradient
          gradientColor={[9, 11, 43]}
          gradientWidth={100}
        >
          {valued_by_marquee_list}
        </Marquee>
      </div>
    </div>
  );
};

export default ValuedBy;
