import React from "react";
import { homePageSlidesData } from "../../../Constants/HomePageConstants";
import { IoLogoYoutube } from "react-icons/io";
import { styles } from "../../../styles";
const HomeSlide2 = ({ id }) => {
  return (
    <div
      id={id}
      className=""
      // className=" relative w-[38rem] min-[360px]:w-[40rem] min-[375px]:w-[42rem] min-[390px]:w-[44rem] min-[412px]:w-[48rem] min-[768px]:w-[70rem] min-[820px]:w-[75rem] min-[1024px]:w-[90rem] min-[1120px]:w-[100rem] min-[1440px]:w-[110rem] min-[1792px]:w-[120rem] md:px-[1rem] lg:px-[4rem] xl:px-[6rem] 2xl:px-[8rem] min-[1848px]:w-[125rem]"
    >
      <div
        className="flex justify-center items-start flex-wrap gap-[2rem] md:gap-[0rem] lg:gap-[6rem] xl:[6rem] 2xl:[8rem]"
      >
        <div className="flex justify-center items-center col-span-9 md:col-span-6">
          <img
            src={homePageSlidesData?.[1]?.image}
            className="w-[23rem] min-[375px]:w-[28rem] min-[420px]:w-[32rem] min-[768px]:w-[30rem] min-[1024px]:w-[40rem] "
            alt="IOT Green Light "
          />
        </div>
        <div className="flex flex-col justify-start items-center md:items-start flex-nowrap col-span-9 md:col-span-6 mt-[2rem] ">
          <p className={`text-start font-[700] text-black text-shadow font-montserrat tracking-wider uppercase text-[3.2rem] leading-normal mt-[1rem] `}>
            {homePageSlidesData?.[1]?.title}
          </p>
          <p
            className={`text-start text-black text-shadow font-montserrat font-medium leading-normal tracking-wider text-[1.4rem] mb-2 mt-2`}
          >
            {homePageSlidesData?.[1]?.subtitle}
          </p>
          <p
            className={`text-start text-[#282828] text-shadow font-montserrat font-medium leading-normal tracking-wider text-[1.4rem] mt-5`}
          >
            {homePageSlidesData?.[1]?.extraInfo}
          </p>
          <p
            className={`text-start text-[#282828] text-shadow font-montserrat font-bold leading-normal tracking-wider text-[3.2rem] mb-2`}
          >
            {homePageSlidesData?.[1]?.saveNumbers}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeSlide2;
