import React from "react";
import { Link } from "react-router-dom";
import { ScrollTrigger, gsap, iotSolutionsListData, useGSAP } from "../../../Constants";
import { styles } from "../../../styles";
gsap.registerPlugin(ScrollTrigger);

const IOT_Solutions = () => {
  useGSAP(() => {
    gsap.fromTo(
      ".iot-solutions-card",
      {
        x : -250,
        opacity : 0,
      },
      {
        opacity: 1,
        duration: 0.5 ,
        x: 0,
        stagger: {
          amount : 1
        },
        scrollTrigger: {
          trigger: "#iot-solutions",
        },
      }
    );
    gsap.fromTo("#iot-solution-title",
    {
      x : -250,
      opacity : 0
    },
    {
      opacity: 1,
      duration: 1 ,
      x: 0,
      scrollTrigger: {
        trigger: "#iot-solutions",
      },
    }
    );
    gsap.fromTo("#iot-solution-sub-title",
    {
      scale : 0
    },
    {
      scale: 1,
      duration: 1 ,
      scrollTrigger: {
        trigger: "#iot-solutions",
      },
    }
    )
  }, []);
  return (
    <div
      className={`${styles.paddingX} ${styles.flexColNWJCIC} `}
      id="iot-solutions"
    >
      <p
       id="iot-solution-title"
        className={`${styles.sectionHeadText} text-center ${styles.marginTop}`}
      >
        IOT SOLUTIONS
      </p>
      <p className={`text-center mt-2 ${styles.sectionSubText}`} id="" >
        END-TO-END PLATFORM SOLUTION
      </p>
      <div className={`py-4 w-full ${styles.flexRowWJCIS} mx-auto gap-[2rem]`}>
        {iotSolutionsListData?.map((solution, index) => (
            <div
            
            className={`iot-solutions-card relative ${styles.flexColNWJSIS} w-[20rem] sm:w-[15rem] h-auto 2xl:w-[16rem] mt-[3rem] transition ease-in-out delay-125 hover:-translate-y-1 hover:scale-110 duration-500 `}
            style={{
              boxShadow: "0px 4px 60.8px 0px rgba(0, 0, 0, 0.3)",
            }}
          >
            <div
              className="fc-item fc-hover w-[100%] "
              onMouseOver={(e) => e.currentTarget.classList.add("flip-content")}
              onMouseOut={(e) =>
                e.currentTarget.classList.remove("flip-content")
              }
            >
              <img
                src={solution?.image}
                alt={solution?.id}
                className="w-[100%] fc-frontcontent rounded-t-[5px]"
              />
              <div
                className={`fc-backcontent ${styles.flexRowWJCIC} w-[100%] h-[100%]`}
                style={{
                  backgroundColor: `${solution?.color}`,
                }}
              >
                <img
                  src={solution?.description_bg_logo}
                  alt={solution?.title}
                  className="w-[50%] opacity-20"
                />
                <ul
                  className={` list-disc absolute top-0 left-4 pt-[4rem] w-[100%] h-[100%] p-[1rem] z-50`}
                >
                  {solution?.description?.map((item, index) => (
                    <li
                      key={index}
                      className=" text-white text-[1rem] md:text-[0.9rem] font-normal p-[0.4rem] text-left leading-normal"
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <Link
              to={`/services/#${solution?.id}`}
              className={`py-6 px-4 w-[100%] h-[7rem] text-start`}
              style={{
                backgroundColor: `${solution?.color}`,
                boxShadow: "0px 4px 60.8px 0px rgba(0, 0, 0, 0.3)",
              }}
            >
              <p className="text-white text-[1.1rem] font-bold pb-1">
                {solution?.title}
              </p>
              <p className="text-white text-[0.9rem] font-medium pb-1">
                {solution?.subtitle}
              </p>
            </Link>

            <div
              style={{ backgroundColor: `${solution?.color}` }}
              className="w-[100%] mt-2"
            >
              <Link to={`/services/${solution?.serviceType?.id}/#${solution?.id}`}>
                <p className=" text-center py-1 text-white text-xl md:text-2xl font-bold cursor-pointer">
                  {">"}
                </p>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IOT_Solutions;
