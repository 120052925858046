import React from 'react'
import ServiceHero from './ServiceHero'
import { servicesData } from '../../../Constants/ServicePageConstants'

const HardwareServices = () => {
  return (
    <ServiceHero serviceObj={servicesData?.find((item) => item?.id === "hardware-services")} /> 
  )
}

export default HardwareServices
