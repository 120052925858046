import React from "react";
import { styles } from "../../../styles";

const RightPlatform = ({services}) => {
  return (
    <div
      className={`${styles.paddingX} ${styles.flexColNWJCIC} ${styles.marginTop} mx-auto relative`}
    >
      <p className="text-[2.2rem] font-semibold text-center tracking-wider mb-[1rem] ">
        Which Platforms are <br /> right for you?
      </p>
      <div
        className={` w-full ${styles.flexRowWJCIS}  lg:p-[2rem] gap-[2rem] `}
      >
        {services?.map(
          (item, index) =>
            item?.utilityProp?.length > 0 && (
              <div
                key={index}
                className={`${styles.flexColNWJCIC} gap-[2rem] mb-[2rem] `}
              >
                <div
                  className={`${styles.flexRowNWJSIS} relative w-[20rem] lg:w-[20rem] mb-[0.9rem] lg:mb-0 gap-[1.5rem] bg-white shadow-lg rounded-xl shadow-gray-200 border-1 border-[#10893A] lg:p-[1rem] p-[0.8rem] `}
                >
                  <img
                    src={item?.logo}
                    alt={item?.title}
                    className={`h-[4rem] lg:h-[3rem] duration-500 mb-[0.6rem] lg:mb-[0.1rem]`}
                  />
                  <div className="flex flex-col items-center lg:items-start mt-[0.2rem] gap-[1rem] lg:gap-[0.5rem] ">
                    <span
                      className={`font-semibold text-[0.9rem] lg:text-[0.85rem] `}
                    >
                      {item?.title}
                    </span>
                    <span
                      className={`font-semibold text-[0.7rem] lg:text-[0.75rem] lg:mt-[0.2rem]`}
                    >
                      {item?.subtitle}
                    </span>
                  </div>
                </div>
                <div className="bg-gray-500 w-full h-[1px] " />
                <div className={`${styles.flexColNWJCIC} gap-[2rem]`}>
                  {item?.utilityProp?.map((utility, index) => (
                    <p key={index} className="text-[0.8rem]">
                      {utility}
                    </p>
                  ))}
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default RightPlatform;
