import React from "react";
import CurrentOpenings from "./CurrentOpenings";
import CareerHero from "./CareerHero";
import RadialGreenGradient from "../../ReusableComponents/RadialGreenGradiant";

const CareerComponent = () => {
  return (
    <div>
        <RadialGreenGradient className={"top-[5%] left-0"} style={{ backgroundImage:
          "radial-gradient(circle 8rem at 0% 50%, rgba(8, 184, 131, 0.4), #0000)",}}/> 
      <CareerHero />
      <RadialGreenGradient className={"top-[24%] right-0"} style={{ backgroundImage:
          "radial-gradient(circle 12rem at 100% 50%, rgba(8, 184, 131, 0.4), #0000)",}}/> 
      <CurrentOpenings />
    </div>
  );
};

export default CareerComponent;
