import React, { useState } from "react";
import { iesglogo, up_arrow_logo } from "../../Assets";
import Button from "../ReusableComponents/Button";
import { address, addressLink, contact, footerLinks, mail } from "../../Constants";
import { styles } from "../../styles";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../context.js/useGlobalContext";
import axios from "axios";
import { toast } from "react-toastify";

const Footer = () => {
  const { setIsBookDemoFormOpen } = useGlobalContext();
  const [formData, setFormData] = useState({
    user_name: "",
    email: "",
    organization: "",
    services : [],
    contact_no: "",
    comment: "",
  });
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    if (!formData.email) {
      newErrors.email = "Email is required";
      valid = false;
    }

    if (!validateEmail(formData?.email)) {
      newErrors.email = "Enter Valid Email";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      axios
        .post("/iesg-website/book-demo", formData)
        .then((response) => {
          console.log(response?.data?.data);
          toast.success("Email Sent successfully");
          setFormData({
            user_name: "",
            email: "",
            organization: "",
            contact_no: "",
            services : [],
            comment: "",
          });
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
      setLoading(false);
    }
  };

  return (
    <div className={`w-full ${styles.flexColNWJCIC} ${styles.marginTop}`}>
      <div className={`${styles.flexRowWJCIS} my-4 gap-[2rem] pr-4 `}>
        <div
          className={`${styles.flexColNWJCIC} w-[24rem] h-[16rem] pl-[6rem] pr-[3rem] rounded-r-[1.6rem]`}
          style={{
            backgroundColor: "rgba(0, 86, 60, 0.10)",
            marginLeft: "0",
          }}
        >
          <img
            src={iesglogo}
            alt="iesglogo"
            className="w-[8rem] h-[6rem] mb-4"
          />
          <Button onClick={() => setIsBookDemoFormOpen(true)} className="bg-[#00563C] rounded-md px-4 py-2 text-[#fff] text-[1rem] text-center font-montserrat text-white text-base font-medium ">
              Book a Demo
            </Button>
        </div>
        {footerLinks?.map((item, index) => (
          <div
            key={index}
            className={`${styles.flexColNWJSIS} gap-4 w-[18rem] md:w-[12rem]`}
          >
            <p className="font-bold text-[#00563C] text-[1.1rem]">
              {item?.title}
            </p>
            <div className={`${styles.flexColNWJCIS} gap-4 text-[0.9rem]`}>
              {item?.sublinks?.map((sublink, index) => (
                <Link
                  to={item?.id === "services" ? `/${item?.id}/${sublink?.serviceType?.id}/#${sublink?.sublinkid}` : `/${item?.id}/#${sublink?.sublinkid}`}
                  key={index}
                  className="font-medium"
                >
                  {sublink?.sublinktitle}
                </Link>
              ))}
            </div>
          </div>
        ))}
        <div
          className={`${styles.flexColNWJCIC} flex flex-col flex-nowrap justify-center items-center md:items-start gap-[1rem]`}
        >
          <div className={`${styles.flexRowWJCIS} gap-[1rem]`}>
            <div className="flex flex-col flex-nowrap w-[18rem] lg:w-[12rem]">
              <p className="font-bold text-[#00563C] mb-4">Address</p>
              <a href={addressLink} target="_blank" rel="noreferrer" className="font-medium mb-8 text-[0.8rem]">{address}</a>
            </div>

            <div
              className={`${styles.flexColNWJCIS} gap-[1rem] w-[18rem] lg:w-[12rem]`}
            >
              <div className={`${styles.flexColNWJSIS}`}>
                <p className="font-bold text-[#00563C] mb-4">Contact</p>
                {contact?.map((x, index) => (
                  <a
                    key={index}
                    href={`tel:${x?.mobileNo}`}
                    className="font-medium mb-4 text-[0.8rem]"
                  >
                    {x?.mobileNo}
                  </a>
                ))}
              </div>
              <div className={`${styles.flexColNWJSIS}`}>
                <p className="font-bold text-[#00563C] mb-4">Mail</p>
                {mail?.map((x, index) => (
                  <a
                    key={index}
                    href={`mailto:${x?.mailId}`}
                    className="font-medium mb-4 text-[0.8rem]"
                  >
                    {x?.mailId}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-nowrap">
            <p className="font-bold text-[#00563C] mb-4">Connect with Us</p>
            <div className="flex justify-start items-center mb-4">
              <div className="flex flex-col" >
              <input
                placeholder="Enter Email"
                style={{ backgroundColor: "rgba(0, 86, 60, 0.10)" }}
                className="p-2 text-[0.8rem] w-[14rem] lg:w-[18rem] rounded-l-[0.4rem]"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
               {errors.email && (
                  <span className="text-[0.8rem] text-red-500 px-[0.5rem]">
                    {errors.email}
                  </span>
                )}
              </div>
              <Button className="bg-[#00563C] rounded-md p-[0.4rem] text-[#fff] text-center font-montserrat text-[0.8rem] text-base font-medium" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-[18rem] md:w-[4rem] my-[1rem] cursor-pointer"
        onClick={() => window.scrollTo({ behavior: "smooth", top: "0" })}
      >
        <span className={`${styles.flexRowNWJCIC}`}>
          <img
            src={up_arrow_logo}
            alt="up_arrow_logo"
            className="object-contain"
          />
          <p className="font-bold text-[1rem] uppercase">TOP</p>
        </span>
      </div>
      <div className="w-full bg-[#000] py-2 text-center">
        <span className="text-center text-white font-montserrat text-base font-medium text-[1rem]">
          {" "}
          © IESGLABS 2024. All rights reserved.{" "}
        </span>
      </div>
    </div>
  );
};

export default Footer;
