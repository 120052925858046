import React from "react";
import { styles } from "../../../styles";
import { currentOpeningsData, gsap, useGSAP , ScrollTrigger } from "../../../Constants";
import { downloading_logo } from "../../../Assets";
gsap.registerPlugin(ScrollTrigger);

const CurrentOpenings = () => {
  useGSAP(() => {
    gsap.fromTo(
      "#career-opening-cards",
      {
        opacity: 0,
        x : -100,
      },
      {
        opacity: 1,
        duration: 1,
        x : 0,
        stagger: {
          amount: 1.5,
          from: "start",
        },
        scrollTrigger: {
          trigger: "#career-opening-cards",
        },
      }
    );
  }, []);

  return (
    <div
      id="career-openings"
      className={`${styles.flexColNWJCIC} mt-[4rem] h-auto`}
      style={{
        backgroundColor: "rgba(77, 172, 157, 0.1)",
        backdropFilter: "blur(10px)",
        boxShadow: "0 0px 40px rgba(77, 172, 157, 0.2)",
        border: "none",
      }}
    >
      <p className={`${styles.sectionHeadText} text-center mt-[2rem]`}>
        CURRENT OPENINGS
      </p>
      {currentOpeningsData?.length > 0 ? (
        <div className={`${styles.flexColNWJCIC} gap-[0.8rem] my-[2.6rem]`}>
          {currentOpeningsData?.map((job, index) => (
            <div
              id="career-opening-cards"
              key={index}
              style={{ boxShadow: "0px 0px 29.3px 0px rgba(0, 0, 0, 0.12)" }}
              className={`${styles.flexRowWJBIC} w-[280px] sm:w-[400px] md:w-[600px] lg:w-[800px] xl:w-[1000px] p-4 rounded-xl bg-[#fff]`}
            >
              <div className={`${styles.flexColNWJCIS} w-[100%] md:w-[60%]`}>
                <p className="font-bold mb-2 text-[1rem]">
                  {job?.position_name}
                </p>
                <p className="font-normal mb-2 text-[0.8rem]">
                  {job?.position_description}
                </p>
              </div>
              <div className={`${styles.flexRowWJCIC}`}>
                <img src={downloading_logo} alt="downloading logo" />
                <p className="font-medium text-blue-600 px-4 text-[1rem]">
                  Job Profile
                </p>
                <button className="bg-[#0F2229] p-2 rounded-lg text-white font-medium text-[0.8rem]">
                  Apply
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-row items-center p-[2rem]">
          <p className="font-[500] text-[1rem] "> No Job Openings</p>
        </div>
      )}
    </div>
  );
};

export default CurrentOpenings;
