import { createContext, useContext, useState } from "react";

const GlobalContext = createContext();

export const useGlobalContext = () => useContext(GlobalContext);

const GlobalContextProvider = ({ children }) => {
  const [isBookDemoFormOpen, setIsBookDemoFormOpen] = useState(false);
  return <GlobalContext.Provider value={{isBookDemoFormOpen, setIsBookDemoFormOpen}} >{children}</GlobalContext.Provider>;
};

export default GlobalContextProvider;