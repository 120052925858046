import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { styles } from "../../styles";
import { navLinkButtons, navLinks } from "../../Constants";
import { iesglogo, close, menu } from "../../Assets";
import Button from "../ReusableComponents/Button";
import { useGlobalContext } from "../../context.js/useGlobalContext";

const Header = () => {
  const [active, setActive] = useState("");
  const [toggle, setToggle] = useState(false);
  const { setIsBookDemoFormOpen } = useGlobalContext();
  const [showDropdown, setShowDropdown] = useState();

  return (
    <div
      className={`${styles.paddingX} py-4 w-full ${styles.flexRowWJBIC} mx-auto`}
    >
      <Link
        to="/"
        className={`${styles.flexRowWJCIC} gap-[0.5rem]`}
        onClick={() => {
          setActive("");
          window.scrollTo(0, 0);
        }}
      >
        <img src={iesglogo} alt="iesglogo" className="w-[6.5rem]" />
      </Link>
      <div className="hidden lg:flex flex-row justify-end items-center flex-nowrap gap-10">
        <ul className="list-none hidden sm:flex flex-row gap-10">
          {navLinks.map((nav) => (
            <li
              key={nav.id}
              className={`${
                active === nav.title ? "text-[#00563C]" : "text-black"
              } hover:text-[#00563C] text-[1rem] ${
                nav?.dropDownMenu?.length === 0 ? "hover:scale-125" : ""
              } duration-300 font-medium cursor-pointer relative z-[99999]`}
              onClick={() => {
                setActive(nav.title);
                if (nav?.dropDownMenu?.length > 0) {
                  setShowDropdown(true);
                }
              }}
              onMouseEnter={() => {
                if (nav?.dropDownMenu?.length > 0) {
                  setShowDropdown(true);
                }
              }}
              onMouseLeave={() => {
                if (nav?.dropDownMenu?.length > 0) {
                  setShowDropdown(false);
                }
              }}
            >
              {nav?.dropDownMenu?.length > 0 ? (
                <p> {nav?.title}</p>
              ) : (
                <Link to={`${nav.id}`}>{nav.title}</Link>
              )}
              {nav?.dropDownMenu?.length > 0 && showDropdown && (
                <div
                  style={{ boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.10)" }}
                  className="absolute min-w-[10rem] top-4 flex flex-col justify-start items-start gap-2 bg-white border-[1px] border-gray-500 py-2 px-4 mt-2 rounded-md "
                >
                  {nav?.dropDownMenu?.map((option) => (
                    <li
                      key={option.id}
                      className={`${
                        active === option.id ? "text-[#00563C]" : "text-black"
                      } hover:text-[#00563C] text-[0.8rem] hover:scale-110 duration-300 font-medium cursor-pointer`}
                      onClick={() => setActive(option.id)}
                    >
                      {" "}
                      <Link to={`${nav?.id}/${option?.id}`}>
                        {option?.title}
                      </Link>{" "}
                    </li>
                  ))}
                </div>
              )}
            </li>
          ))}
        </ul>
        <ul className="list-none hidden sm:flex flex-row gap-10">
          {navLinkButtons?.map((nav, index) => {
            return (
              <li key={index}>
                <Button
                  key={nav?.id}
                  className="bg-[#00563C] rounded-md px-4 py-2 text-center font-montserrat text-white text-base font-medium text-[1rem]"
                  onClick={() => setIsBookDemoFormOpen(true)}
                >
                  {nav?.title}
                </Button>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="lg:hidden flex flex-1 justify-end items-center">
        <img
          src={toggle ? close : menu}
          alt="menu"
          className="w-[3rem]"
          onClick={() => setToggle(!toggle)}
          style={{ zIndex: "1000" }}
        />

        <div
          className={`${
            !toggle ? "hidden" : "flex"
          } p-6 bg-white absolute top-20 right-[2rem] mx-4 my-2 min-w-[10rem] z-[9999] rounded-xl`}
          style={{ border: "1px solid #000" }}
        >
          <ul className="list-none flex justify-end items-start flex-1 flex-col gap-[1rem]  ">
            {navLinks.map((nav) => (
              <li
                key={nav.id}
                className={`font-poppins font-medium cursor-pointer text-[1.1rem] relative  ${
                  active === nav.title ? "text-[#00563C]" : "text-black"
                }`}
                onClick={() => {
                  setActive(nav.title);
                  if(nav?.dropDownMenu?.length < 0){
                    setToggle(false);
                  }
                  if (nav?.dropDownMenu?.length > 0) {
                    setShowDropdown(true);
                  }
                }}
                onMouseEnter={() => {
                  if (nav?.dropDownMenu?.length > 0) {
                    setShowDropdown(true);
                  }
                }}
                onMouseLeave={() => {
                  if (nav?.dropDownMenu?.length > 0) {
                    setShowDropdown(false);
                  }
                }}
              >
                {nav?.dropDownMenu?.length > 0 ? (
                  <p> {nav?.title}</p>
                ) : (
                  <Link onClick={() => setToggle(false)} to={`${nav.id}`}>{nav.title}</Link>
                )}
                {nav?.dropDownMenu?.length > 0 && showDropdown && (
                  <div
                    style={{ boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.10)" }}
                    className="absolute min-w-[9rem] top-4 flex flex-col justify-start items-start gap-2 bg-white border-[1px] border-gray-500 py-2 px-4 mt-2 rounded-md z-[9] "
                  >
                    {nav?.dropDownMenu?.map((option) => (
                      <li
                        key={option.id}
                        className={`${
                          active === option.id ? "text-[#00563C]" : "text-black"
                        } hover:text-[#00563C] text-[0.7rem] hover:scale-110 duration-300 font-medium cursor-pointer`}
                        onClick={() => setActive(option.id)}
                      >
                        {" "}
                        <Link to={`${nav?.id}/${option?.id}`} onClick={() => setToggle(false)} >
                          {option?.title}
                        </Link>{" "}
                      </li>
                    ))}
                  </div>
                )}
              </li>
            ))}
            <li
              key={"book-a-demo"}
              className={`font-poppins font-medium cursor-pointer text-[1.1rem] ${
                active === "Book Demo" ? "text-[#00563C]" : "text-black"
              }`}
              onClick={() => {
                setToggle(!toggle);
                setActive("Book Demo");
                setIsBookDemoFormOpen(true);
              }}
            >
              <button to={`/about/#book-a-demo`}>Book Demo</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
