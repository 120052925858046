import React from "react";
import { line_dash_img } from "../../Assets";
import { styles } from "../../styles";

const Dash = () => {
  return (
    <div
      className={`${styles.paddingX} w-full flex flex-row justify-end items-start flex-wrap ${styles.marginTop}`}
    >
      <img src={line_dash_img} alt="line_dash_img" />
    </div>
  );
};

export default Dash;
