import React from 'react'
import ProductHero from './ProductHero'

const ProductComponent = () => {
  return (
    <div>
      <ProductHero/>
    </div>
  )
}

export default ProductComponent
