const styles = {
  paddingX:
    "sm:px-16 md:px-18 lg:px-20 xl:px-[6rem] 2xl:px-[10rem] 3xl:[10rem] 4xl:[12rem] px-6",
  paddingY: "sm:py-16 py-6",
  padding: "sm:px-16 px-6 sm:py-16 py-10",

  heroHeadText:
    "font-[700] text-black text-shadow font-montserrat tracking-wider uppercase text-[2.2rem] leading-normal mt-[1rem]",
  heroSubText:
    "text-black text-shadow font-montserrat font-normal leading-normal tracking-wider text-[0.8rem] my-2",

  sectionHeadText:
    "text-[#4A4A4A] font-[600] text-[2.7rem] uppercase",
  sectionSubText:
    "text-[#00563C] font-bold xl:text-[18px] lg:text-[14px] md:text-[12px] sm:text-[10px] text-[8px]",
  flexRowWJCIC: "flex flex-row flex-wrap justify-center items-center",
  flexRowNWJCIC: "flex flex-row flex-nowrap justify-center items-center",
  flexRowWJCIS: "flex flex-row flex-wrap justify-center items-start",
  flexRowWJSIS: "flex flex-row flex-wrap justify-start items-start",
  flexRowWJBIS: "flex flex-row flex-wrap justify-between items-start",
  flexRowNWJSIS: "flex flex-row flex-nowrap justify-start items-start",
  flexRowWJSIC: "flex flex-row flex-wrap justify-start items-center",
  flexRowWJSIC: "flex flex-row flex-wrap justify-start items-center",
  flexRowNWJSIC: "flex flex-row flex-nowrap justify-start items-center",
  flexRowWJBIC: "flex flex-row flex-wrap justify-between items-center",
  flexRowNWJBIC: "flex flex-row flex-nowrap justify-between items-center",
  flexRowWJSIE: "flex flex-row flex-nowrap justify-start items-end",

  flexColWJCIC: "flex flex-col flex-wrap justify-center items-center",
  flexColNWJCIC: "flex flex-col flex-nowrap justify-center items-center",
  flexColWJSIS: "flex flex-col flex-wrap justify-start items-start",
  flexColNWJSIS: "flex flex-col flex-nowrap justify-start items-start",
  flexColNWJCIS: "flex flex-col flex-nowrap justify-center items-start",

  marginTop: "mt-[5rem]",
  marginBottom: "mb-6 sm:mb-8 md:mb-10 lg:mb-12 xl:mb-14 2xl:mb-16",
};

export { styles };
