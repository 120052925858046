import React, { useEffect } from "react";
import { companyGrowthFigures, gsap, useGSAP , ScrollTrigger } from "../../../Constants";
import { styles } from "../../../styles";
import { scrolldown } from "../../../Assets";
import HomeSlide1 from "./HomeSlide1";
import HomeSlide2 from "./HomeSlide2";

// Swiper Imports
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay , Pagination} from "swiper/modules";

const HomeHero = () => {
  const handleScrollDown = () => {
    const currentPosition =
      window.scrollY || document.documentElement.scrollTop;
    const newPosition = currentPosition + window.innerHeight;
    window.scrollTo({
      top: newPosition,
      behavior: "smooth",
    });
  };

  // const counter = (id, start, end, duration) => {
  //   let obj = document.getElementById(id),
  //    current = start,
  //    range = end - start,
  //    increment = end > start ? 1 : -1,
  //    step = Math.abs(Math.floor(duration / range)),
  //    timer = setInterval(() => {
  //     current += increment;
  //     obj.textContent = current;
  //     if (current == end) {
  //      clearInterval(timer);
  //     }
  //    }, step);
  //  }

  const animateValue = (obj, start, end, duration, abbreviation) => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = `${Math.floor(
        progress * (end - start) + start
      )}${abbreviation}+`;
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  useEffect(() => {
    companyGrowthFigures.forEach((item) => {
      const obj = document.getElementById(item.id);
      animateValue(
        obj,
        0,
        item?.count,
        item?.animation_duration,
        item?.abbreviation
      );
    });
  }, []);

  useGSAP(() => {
    gsap.fromTo(
      "#growthFigures",
      {
        opacity: 0,
      },
      {
        duration: 1,
        ease: "power1.inOut",
        opacity: 1,
        stagger: {
          amount: 1.5,
          from: "start",
        },
      }
    );
  }, []);

  return (
    <>
      <div className={`overflow-hidden h-auto relative flex items-center justify-center ${styles.paddingX}`}>
        <Swiper
          className={`flex items-center justify-center py-[1rem] h-auto `}
          grabCursor={true}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          speed={1000}
          modules={[Autoplay , Pagination]}
          pagination={
            {
              clickable : true,
            }
          }
          style={{
            "--swiper-pagination-color": "#00563C",
            "--swiper-pagination-bullet-size": "10px",
            "--swiper-pagination-bullet-inactive-color": "#00563C",
            "--swiper-pagination-bullet-inactive-size": "6px",
          }}
        >
          <SwiperSlide >
            <HomeSlide1 id={`slider`} />
          </SwiperSlide>
          <SwiperSlide >
            <HomeSlide2 id={`slider`} />
          </SwiperSlide>
        </Swiper>
      </div>
      {/* <div
          className={`${styles.flexRowWJCIC} gap-[1.4rem] ${styles.marginTop}`}
        >
          {allDashboardData?.map((project, index) => (
            <img
              key={index}
              src={project?.image}
              alt={project?.name}
              className="w-[19rem]"
              style={{ boxShadow: "0px 4px 60.8px 0px rgba(0, 0, 0, 0.20)" }}
            />
          ))}
        </div> */}
      {/* <div className={`w-full`}>
        <ProjectSlider />
      </div> */}

      <div
        className={`${styles.paddingX} grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 py-[1rem] w-full mx-auto my-[0.4rem] gap-[1.6rem]`}
        // style={{
        //   display: "grid",
        //   gridTemplateColumns: "repeat(auto-fit, minmax(26rem, 1fr))",
        //   gridAutoFlow:'row',
        //   gap:"1rem"
        // }}
      >
        {companyGrowthFigures?.map((item, index) => (
          <div
            id="growthFigures"
            key={index}
            className={`${styles.flexColNWJCIC} min-w-[20rem] gap-[0.2rem]`}
            // style={{
            //   display: "grid",
            //   gridTemplateColumns: "repeat(auto-fit, minmax(2rem, 1fr))",
            //   gridAutoFlow:'row',
            // }}
          >
            <p className="text-[1.4rem] font-medium">{item?.title}</p>
            <p id={item?.id} className="text-[1.8rem] font-bold">
              {item?.count}+
            </p>
          </div>
        ))}
      </div>
      <div
        className={`${styles.flexRowNWJCIC} cursor-pointer`}
        onClick={handleScrollDown}
      >
        <img src={scrolldown} alt="scrolldown" className="object-contain" />
      </div>
    </>
  );
};

export default HomeHero;
