import React from "react";
import { homePageSlidesData } from "../../../Constants/HomePageConstants";
import { IoLogoYoutube } from "react-icons/io";
import { styles } from "../../../styles";

// GSAP Imports
import { gsap, useGSAP, ScrollTrigger } from "../../../Constants";

const HomeSlide1 = ({ id }) => {
  useGSAP(() => {
    gsap.fromTo(
      "#title",
      {
        opacity: 0,
        y: 20,
      },
      {
        duration: 1,
        ease: "power1.inOut",
        opacity: 1,
        y: 0,
      }
    );
    gsap.to("#letsTransformBtn", {
      duration: 1,
      scale: 1.1,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });

    gsap.fromTo(
      "#subtitle",
      {
        opacity: 0,
        y: 20,
      },
      {
        ease: "power1.inOut",
        opacity: 1,
        duration: 1,
        delay: 0.5,
        y: 0,
      }
    );
  }, []);

  return (
    <div
      id={id}
      // className=" relative w-[27rem] min-[360px]:w-[30rem] min-[375px]:w-[31rem] min-[412px]:w-[35rem] min-[768px]:w-[62rem] min-[1024px]:w-[76rem] min-[1120px]:w-[80rem] min-[1440px]:w-[95rem] min-[1792px]:w-[115rem] min-[1848px]:w-[120rem] md:px-[1rem] lg:px-[4rem] xl:px-[6rem] 2xl:px-[8rem] "
    >
      <div
        // className={`${styles.paddingX} py-[1rem] w-full lg:w-[100%] mx-auto grid grid-cols-12 gap-4 sm:gap-8 md:gap-12 lg:gap-16`}
          className="flex justify-center items-start flex-wrap gap-[2rem] lg:gap-[6rem] xl:[6rem] 2xl:[8rem] "
      >
        <div className="flex flex-col justify-center items-start flex-nowrap w-[80%] md:w-[50%] col-span-12 md:col-span-7 min-[800px]:col-span-8 min-[1792px]:col-span-7 ">
          <p id="title" className={`text-start ${styles.heroHeadText} `}>
            {homePageSlidesData?.[0]?.title}
          </p>
          <p
            id="subtitle"
            className={`text-start text-black text-shadow font-montserrat font-normal leading-normal tracking-wider text-[1rem] my-4`}
          >
            {homePageSlidesData?.[0]?.subtitle}
          </p>

          <a
            activeClass="active"
            href={"#iot-solutions"}
            className="cursor-pointer"
            id="letsTransformBtn"
          >
            <div
              className={`w-[10rem] ${styles.flexRowNWJBIC} border border-black rounded-md my-4`}
            >
              <p className=" p-1 text-[0.8rem]">Let's Transform</p>
              <IoLogoYoutube className="text-[#00563C] w-[1.8rem] h-[1.4rem]" />
            </div>
          </a>
        </div>
        <div className="flex justify-center items-center  col-span-12 md:col-span-3">
          <img
            src={homePageSlidesData?.[0]?.image}
            className="h-[24rem]"
            alt="IOT Green Light "
          />
        </div>
      </div>
    </div>
  );
};

export default HomeSlide1;
