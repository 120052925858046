export const careerHeroTitle =
  "Join Our Team and Shape a Sustainable Future with";
export const careerHeroSubtitle =
  "At iESG Labs pvt ltd., we believe in harnessing the power of technology to drive positive change and build a sustainable future. If you are passionate about making a meaningful impact and want to be part of a team that is at the forefront of sustainability technology, you've come to the right place!";
export const whyChooseIesgData = [
  {
    id: 1,
    title: "Mission-Driven Purpose",
    description:
      "At the core of our company is a commitment to creating innovative solutions that address pressing global challenges. Our mission is to leverage technology for the betterment of our planet, promoting sustainable practices and environmental responsibility.",
  },
  {
    id: 2,
    title: "Cutting-Edge Technology",
    description:
      "Join a dynamic team that thrives on pushing boundaries. We work with the latest technologies to develop groundbreaking solutions that contribute to a more sustainable world. From renewable energy to resource optimization, your work at IESG Labs Pvt Ltd will be at the forefront of technological innovation. ",
  },
  {
    id: 3,
    title: "Collaborative and Inclusive Culture",
    description:
      "We value diversity and believe that a variety of perspectives leads to the most creative and effective solutions. Our inclusive culture fosters collaboration, ensuring that every team member's unique strengths contribute to our shared success.",
  },
];

export const currentOpeningsData = [
  // {
  //   id: 1,
  //   position_name: "React Developer",
  //   position_description:
  //     "A React developer uses their programming skills to create JavaScript-based applications for web or mobile environments.",
  //   job_description_link: "/",
  // },
  // {
  //   id: 2,
  //   position_name: "React Native Developer",
  //   position_description:
  //     "A React developer uses their programming skills to create JavaScript-based applications for web or mobile environments.",
  //   job_description_link: "/",
  // },
  // {
  //   id: 3,
  //   position_name: "Android Developer",
  //   position_description:
  //     "A React developer uses their programming skills to create JavaScript-based applications for web or mobile environments.",
  //   job_description_link: "/",
  // },
  // {
  //   id: 4,
  //   position_name: "Web Developer",
  //   position_description:
  //     "A React developer uses their programming skills to create JavaScript-based applications for web or mobile environments.",
  //   job_description_link: "/",
  // },
];
