import React from "react";
import IntegrationParterns from "./IntegrationParterns";
import ESGPlatAndPracPartners from "./ESGPlatAndPracPartners";
import CmsParterns from "./CmsParterns";
import TechnologyPartners from "./TechnologyPartners";
import { gsap , useGSAP , ScrollTrigger } from "../../../Constants";
import { styles } from "../../../styles";
gsap.registerPlugin(ScrollTrigger);

const AllPartners = () => {
  useGSAP(() => {
    gsap.fromTo(
      "#partners",
      {
        opacity: 0,
        y: 20,
      },
      {
        duration: 1,
        ease: "power1.inOut",
        opacity: 1,
        y: 0,
        stagger:{
          amount: 1,
          axis : "x",
          from : "start"
        },
        scrollTrigger: {
          trigger: "#partners",
        },
      }
    );
  }, []);

  return (
    <div
      className={`${styles.marginTop} flex flex-row justify-center items-center flex-wrap 2xl:flex-nowrap gap-[2rem] `}
      style={{
        backgroundColor: "rgba(77, 172, 157, 0.1)",
        backdropFilter: "blur(10px)",
        boxShadow: "0 0px 40px rgba(77, 172, 157, 0.2)",
        border: "none",
      }}
    >
      <IntegrationParterns id={"partners"} />
      <ESGPlatAndPracPartners id={"partners"} />
      <CmsParterns id={"partners"} />
      <TechnologyPartners id={"partners"} />
    </div>
  );
};

export default AllPartners;
