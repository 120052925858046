import React from "react";
import { integrationPartnersData } from "../../../Constants";
import { styles } from "../../../styles";

const IntegrationParterns = ({ id }) => {
  return (
    <div
      id={id}
      className={`${styles.flexColNWJCIC} p-[2rem] w-[100%] md:w-[30%] lg:w-[20%] 2xl:w-[40%]`}
      
    >
      <p
        className={`text-[#4A4A4A] font-[600] uppercase text-center text-[1.2rem]`}
      >
        INTEGRATION <br /> PARTNERS
      </p>
      <div className={`w-full`}>
        {integrationPartnersData?.map((partner, index) => (
          <div
            key={index}
            className={`flex flex-row justify-center items-center p-[2rem] py-[2.4rem] `}
          >
            <img
              src={partner?.company_logo}
              alt={partner?.company_name}
              className="h-[5rem]"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default IntegrationParterns;
