import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import Layout from "./Components/Layout/Layout";
import ProductPage from "./Pages/ProductPage";
import AboutPage from "./Pages/AboutPage";
import ServicePage from "./Pages/ServicePage";
import CareerPage from "./Pages/CareerPage";
import { ParallaxProvider } from "react-scroll-parallax";
import BookDemoPopup from "./Components/ReusableComponents/BookDemoPopup";
import PlatformServices from "./Components/SubComponents/Service/PlatformServices";
import HardwareServices from "./Components/SubComponents/Service/HardwareServices";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <ParallaxProvider>
          <Routes>
            <Route key="layout" path="/" element={<Layout />}>
              <Route key="index" index element={<HomePage />} />
              <Route key="products" path="products" element={<ProductPage />} />
              <Route key="career" path="career" element={<CareerPage />} />
              <Route key="services" path="services" element={<ServicePage />} >
                <Route key="platform-services" path="platform-services" element={<PlatformServices/>} />
                <Route key="hardware-services" path="hardware-services" element={<HardwareServices/>} />
              </Route>
              <Route key="about" path="about" element={<AboutPage />} />
            </Route>
          </Routes>
          <BookDemoPopup/>
        </ParallaxProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
