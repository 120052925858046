// import {
//   iesg_ione_product_image,
//   iot_panel_image,
//   ir_blaster_image,
//   sustain_analytics_img,
//   sustain_analytics_logo,
//   sustain_connect_img,
//   sustain_connect_logo,
//   sustain_sphere_logo,
//   sustainshere_img,
//   temperature_device_image,
//   watt_wise_logo,
//   wattwise_img,
// } from "../Assets";

// export const productsData = [
//   {
//     id: "iesg-ione",
//     title: "IESG - iONE",
//     subtitle: "(Gateway)",
//     logo: iesg_ione_product_image,
//     image: iesg_ione_product_image,
//     home_description : "Our innovative IoT-enabled dashboard drives sustainable practices, harnessing technology for a greener future.",
//     technical_specifications: {
//       "Linux based Modbus to TCPIP gateway with": [
//         "ARM CORTEX Processor",
//         "2GB RAM, 32GB SSD",
//       ],
//       "Hardware Interface": [
//         "4 USB Ports",
//         "1 Ethernet port",
//         "1 RS485 Modbus Port. 4 Isolated Input.",
//         "4 Relay Output",
//         "1 HDMI",
//       ],
//     },
//     description: {
//       "Monitoring device protocol supported": [
//         "Modbus RS485, TCPIP Modbus, Bacnet, Serial",
//         "Programmable baud rate [should be same for all devices",
//       ],
//       "Communication protocol.": [
//         "HTTP REST API data push with Jason format for transaction.",
//         "If no internet, data is backed up and pushed once network is stable",
//         "SSH",
//         "MQTT for Real-time data push",
//       ],
//       "Local data storage for 10k transactions.": [],
//       "Available with IAM cloud store or configurable cloud IP. Programmable alarm set points.":
//         [],
//       "Supports 16 Modbus slaves": [],
//       " Supports total 256 Modbus registers.": [],
//       " Polling frequency programmable from 1 min to 30 min Real-time data over MQTT protocol.":
//         [],
//       "12 V, 5A Power supply. Metal enclosure.": [],
//       "Enclosure Size - 182 x 86 x 52 (LxWxH)": [],
//       "Recommended to install at location where room temperature is at 26°C max.":
//         [],
//       "Recommended to provide UPS based power": [],
//     },
//     application: [
//       "Energy / Utilities Monitoring",
//       "HVAC Monitoring / Control",
//       "Light Control",
//       "Schedule based Smart Controlling",
//       "Temperature / Humidity Monitoring",
//     ],
//   },
//   {
//     id: "ir-blaster",
//     title: "IR Control Hub",
//     subtitle: "",
//     logo: ir_blaster_image,
//     image: ir_blaster_image,
//     home_description : "Our innovative IoT-enabled dashboard drives sustainable practices, harnessing technology for a greener future.",
//     description: {
//       "Monitoring device protocol supported.": [
//         "Modbus RS485, TCP Modbus",
//         "Programmable baud rate should be same for all devices",
//       ],
//       "Communication protocol.": [
//         "MQTT for Real-time data push.",
//         "Schedule based and temperature based control.",
//       ],
//       "Programmable temperature and alarm set points.": [],
//       "Supports 16 Modbus slaves": [],
//       "Polling frequency programmable from 1 min to 30 min.": [],
//       "12 V, 2A Power supply.": [],
//     },
//     technical_specifications: {
//       // "Wifi based AC control system": ["ARM CORTEX Processor"],
//       "": [
//         "IR transmitter & Receiver",
//         "1 Temperature sensor",
//         "2 isolated digital inputs",
//         "2 digital outputs (Potential Free)",
//         "Wifi",
//         "1 RS485 Modbus port",
//       ],
//     },
//     application: [
//       "Control of split, cassette AC’s through IR based on time",
//       "Control of Ductable and VRV through modbus",
//       "Compatible with Bluestar, Carrier, Midea, Hitachi, Dikan, Delta and more...",
//     ],
//   },
//   {
//     id: "temperature-device",
//     title: "Temperature Device",
//     subtitle: "",
//     logo: temperature_device_image,
//     image: temperature_device_image,
//     home_description : "Our innovative IoT-enabled dashboard drives sustainable practices, harnessing technology for a greener future.",
//     description: {
//       "Monitoring device protocol supported.": [
//         "Modbus RS485, TCP Modbus",
//         "Programmable baud rate should be same for all devices",
//       ],
//       "Communication protocol.": ["MQTT for Real-time data push."],
//       "Programmable temperature and alarm set points.": [],
//       "Polling frequency programmable from 1 min to 30 min.": [],
//       "12 V, 2A Power supply.": [],
//     },
//     technical_specifications: {
//       // "Wifi based AC control system": ["ARM CORTEX Processor"],
//       "": ["1 Temperature - Humidity sensor", "Wifi"],
//     },
//     application: ["Temperature / Humidity Monitoring"],
//   },
//   {
//     id: "iot-panel",
//     title: "IOT Panel",
//     subtitle: "",
//     logo: iot_panel_image,
//     image: iot_panel_image,
//     home_description : "Our innovative IoT-enabled dashboard drives sustainable practices, harnessing technology for a greener future.",
//     description: {
//       "Monitoring device protocol supported.": [
//         "Modbus RS485, TCP Modbus",
//         "Programmable baud rate should be same for all devices",
//       ],
//       "Communication protocol.": ["MQTT for Real-time data push."],
//       "Programmable temperature and alarm set points.": [],
//       "Polling frequency programmable from 1 min to 30 min.": [],
//       "12 V, 2A Power supply.": [],
//     },
//     technical_specifications: {
//       // "Wifi based AC control system": ["ARM CORTEX Processor"],
//       "": ["TailorMade Solution"],
//     },
//     application: [
//       "A tailormade solution",
//       "Energy / utility monitoring",
//       "Smart control",
//       "Customized as an announciator",
//     ],
//   },
// ];

import {
  annunciator_image,
  iesg_ione_product_image,
  iot_panel_image,
  ir_blaster_image,
  sustain_analytics_img,
  sustain_analytics_logo,
  sustain_connect_img,
  sustain_connect_logo,
  sustain_sphere_logo,
  sustainshere_img,
  temperature_device_image,
  watt_wise_logo,
  wattwise_img,
} from "../Assets";

export const productsData = [
  {
    id: "iesg-ione",
    title: "IESG - iONE",
    subtitle: "(Gateway)",
    logo: iesg_ione_product_image,
    image: iesg_ione_product_image,
    description: [
      {
        name: "Monitoring device protocol supported",
        subpoints: [
          {
            subPointName: "Modbus RS485, TCPIP Modbus, Bacnet, Serial",
          },
        ],
      },
      {
        name: "Cloud protocol.",
        subpoints: [
          {
            subPointName: "HTTP REST API data push with Jason format.",
          },
        ],
      },
      // {
      //   name: "Local data storage for 10k transactions.",
      //   subpoints: [],
      // },
      // {
      //   name: "Available with IAM cloud store or configurable cloud IP. Programmable alarm set points.",
      //   subpoints: [],
      // },
      // {
      //   name: "Supports 16 Modbus slaves",
      //   subpoints: [],
      // },
      // {
      //   name: "Polling frequency programmable from 1 min to 30 min Real-time data over MQTT protocol.",
      //   subpoints: [],
      // },
      // {
      //   name: "12 V, 5A Power supply. Metal enclosure.",
      //   subpoints: [],
      // },
      // {
      //   name: "Enclosure Size - 182 x 86 x 52 (LxWxH)",
      //   subpoints: [],
      // },
      // {
      //   name: "Recommended to install at location where room temperature is at 26°C max.",
      //   subpoints: [],
      // },
    ],
    detailed_description : "Local data storage for 10k transactions. Available with IAM cloud store or configurable cloud IP . Programmable alarm set points.Supports 16 Modbus slaves. Polling frequency programmable from 1 min to 30 min Real-time data over MQTT protocol. 12 V, 5A Power supply. Metal enclosure. Enclosure Size - 182 x 86 x 52 (LxWxH).Recommended to install at location where room temperature is at 26°C max.",
    technical_specifications: [],
    application: [
      "Energy / Utilities Monitoring",
      "HVAC Monitoring / Control",
      "Light Control",
      "Schedule based Smart Controlling",
      "Temperature / Humidity Monitoring",
    ],
  },
  {
    id: "ir-blaster",
    title: "IR Control Hub",
    subtitle: "",
    logo: ir_blaster_image,
    image: ir_blaster_image,
    description: [
      {
        name: "Monitoring device protocol supported.",
        subpoints: [
          {
            subPointName: "Modbus RS485, TCP Modbus",
          },
        ],
      },
      {
        name: "Communication protocol.",
        subpoints: [
          {
            subPointName: "MQTT for Real-time data push.",
          },
          {
            subPointName: "Occupancy / Motion sensor based control",
          },
          {
            subPointName: "Schedule based and temperature based control.",
          },
        ],
      },
      // {
      //   name: "Programmable temperature and alarm set points.",
      //   subpoints: [],
      // },
      // {
      //   name: "Supports 12 Modbus slaves",
      //   subpoints: [],
      // },
      // {
      //   name: "Polling frequency programmable from 1 min to 30 min.",
      //   subpoints: [],
      // },
      // {
      //   name: "12 V, 2A Power supply.",
      //   subpoints: [],
      // },
    ],
    detailed_description: "Programmable temperature and alarm set points. Supports 12 Modbus slaves. Polling frequency programmable from 1 min to 30 min. 12 V, 2A Power supply.",
    technical_specifications: [
      "IR transmitter & Receiver",
      "1 Temperature sensor",
      "2 isolated digital inputs",
      "2 digital outputs (Potential Free)",
      "Wifi",
      "1 RS485 Modbus port",
    ],
    application: [
      "Control of split, cassette AC’s through IR based on time",
      "Compatible with Bluestar, Carrier, Midea, Hitachi, Dikan, Delta and more...",
    ],
  },
  {
    id: "temperature-device",
    title: "Temperature Device",
    subtitle: "",
    logo: temperature_device_image,
    image: temperature_device_image,
    description : [
      {
        name: "Monitoring device protocol supported.",
        subpoints: [
          {
            subPointName : "Modbus RS485, TCP Modbus"
          }
        ],
      },
      {
        name: "Communication protocol.",
        subpoints: [
          {
            subPointName: "MQTT for Real-time data push.",
          },
          {
            subPointName: "Occupancy / Motion sensor based control",
          },
        ],
      },
      // {
      //   name: "Programmable temperature and alarm set points.",
      //   subpoints: [],
      // },
      // {
      //   name: "Supports 12 Modbus slaves",
      //   subpoints: [],
      // },
      // {
      //   name: "Polling frequency programmable from 1 min to 30 min.",
      //   subpoints: [],
      // },
      // {
      //   name: "12 V, 2A Power supply.",
      //   subpoints: [],
      // },
    ],
    detailed_description: "Programmable temperature and alarm set points. Supports 12 Modbus slaves. Polling frequency programmable from 1 min to 30 min. 12 V, 2A Power supply.",
    technical_specifications: ["1 Temperature - Humidity sensor", "Wifi"],
    application: [
      "Temperature / Humidity Monitoring, Motion Input Detection, Modbus Device Detection",
    ],
  },
  {
    id: "iot-panel",
    title: "IOT Panel",
    subtitle: "",
    logo: iot_panel_image,
    image: iot_panel_image,
    description : [
      {
        name: "3 IOT Gateway Support",
        subpoints: [],
      },
      {
        name: "4G Sim Router",
        subpoints: [],
      },
      {
        name: "Digital Input/Output Detection",
        subpoints: [],
      },
      {
        name: "230v AC Support",
        subpoints: [],
      },
      {
        name: "Multiple Modbus/Backnet Support",
        subpoints: [],
      },
    ],
    technical_specifications: ["TailorMade Solution"],
    application: [
      "A tailormade solution",
      "Energy / utility monitoring",
      "Smart control",
    ],
  },

  {
    id: "annunciator",
    title: " Annunciator ",
    subtitle: "",
    logo: annunciator_image,
    image: annunciator_image,
    description :[
      {
        name : "8 Zone Annunciator (16 Zones Expandable)",
        subpoints : []
      },
      {
        name : "Inbuilt 4G SIM router",
        subpoints : []
      },
      {
        name : "IOT Gateway (to connect Energy, AC and Wireless IOT Devices)",
        subpoints : []
      },
      {
        name : "Configurable alert system",
        subpoints : []
      },
      {
        name : "Wireless Annunciation Display.",
        subpoints : []
      },
    ],
    technical_specifications: ["TailorMade Solution"],
    application: [
      "A tailormade solution",
      "Energy / utility monitoring",
      "Smart control",
    ],
  },
];
