import React from 'react'
import { Outlet } from 'react-router-dom'

const ServicePage = () => {
  return (
    <Outlet/>
  )
}

export default ServicePage
