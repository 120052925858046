import React, { useState } from "react";
import { useGlobalContext } from "../../context.js/useGlobalContext";
import { styles } from "../../styles";
import axios from "axios";
import { toast } from "react-toastify";
import { allServices, servicesData } from "../../Constants/ServicePageConstants";
import { Button } from "react-scroll";
import Loader from "./Loader";

const BookDemoPopup = () => {
  const { isBookDemoFormOpen, setIsBookDemoFormOpen } = useGlobalContext();

  const [formData, setFormData] = useState({
    user_name: "",
    email: "",
    organization: "",
    contact_no: "",
    services: [],
    comment: "",
  });
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    if (!formData.user_name) {
      newErrors.user_name = "Name is required";
      valid = false;
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
      valid = false;
    }

    if (!validateEmail(formData?.email)) {
      newErrors.email = "Enter Valid Email";
      valid = false;
    }

    if (!formData.organization) {
      newErrors.organization = "Organization name is required";
      valid = false;
    }

    if (!formData.contact_no) {
      newErrors.contact_no = "Contact number is required";
      valid = false;
    }

    if (formData.services.length === 0) {
      newErrors.services = "At least one service is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData({
        ...formData,
        services: [...formData.services, value],
      });
    } else {
      setFormData({
        ...formData,
        services: formData.services.filter((service) => service !== value),
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      axios
        .post("/iesg-website/book-demo", formData)
        .then((response) => {
          console.log(response?.data?.data);
          toast.success("Form submitted successfully");
          setFormData({
            user_name: "",
            email: "",
            organization: "",
            contact_no: "",
            services: [],
            comment: "",
          });
          setIsBookDemoFormOpen(false);
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
      setLoading(false);
    }
  };

  if (!isBookDemoFormOpen) return null;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 z-[9999]`}
    >
      <div
        className={`${styles.flexColNWJCIC} bg-white rounded-[1rem]  `}
        style={{ zIndex: "2000" }}
        id="book-a-demo"
      >
        {!loading ? (
          <form
            id="book-demo-form"
            style={{
              background:
                "linear-gradient(270deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%)",
              boxShadow: "0px 4px 11.4px 1px rgba(151, 151, 151, 0.25)",
            }}
            onSubmit={handleSubmit}
            className={`bg-[#fff] p-8 w-[320px] sm:w-[400px] md:w-[450px] lg:w-[550px] xl:w-[550px] 2xl:w-[580px] rounded-[1rem] ${styles.flexColNWJCIS}`}
          >
            <div className="w-full flex justify-between items-center mb-[1rem]">
              <p className="uppercase font-bold text-[#4A4A4A] text-[1.6rem] mb-2">
                Book a Demo
              </p>
              {/* <button
                className="w-[6rem] px-2 py-2 bg-gray-300 rounded-md my-1"
                onClick={() => setIsBookDemoFormOpen(false)}
              >
                Cancel
              </button> */}
            </div>
            <p className="font-normal text-[0.8rem] mb-4">
              By submitting this form, I consent to the processing of the
              personal data that I provide to IESG Pvt. Ltd. in accordance with
              and as described in the Privacy Policy. Refund Policy, Terms and
              Condition
            </p>
            <div className={`${styles.flexRowWJSIC} gap-4 lg:gap-6`}>
              <div className={`${styles.flexColNWJSIS} w-[45%] gap-[0.6rem] `}>
                <input
                  style={{ background: "rgba(0, 86, 60, 0.10)" }}
                  className="rounded-lg p-4 text-[0.8rem] w-[100%] "
                  placeholder="Enter Your Name"
                  name="user_name"
                  value={formData.user_name}
                  onChange={handleInputChange}
                />
                {errors.user_name && (
                  <span className="text-[0.8rem] text-red-500 px-[0.5rem]">
                    {errors.user_name}
                  </span>
                )}
              </div>

              <div className={`${styles.flexColNWJSIS} w-[45%] gap-[0.6rem] `}>
                <input
                  style={{ background: "rgba(0, 86, 60, 0.10)" }}
                  className="rounded-lg p-4 text-[0.8rem]  w-[100%] "
                  placeholder="Drop Your Email ID Here"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {errors.email && (
                  <span className="text-[0.8rem] text-red-500 px-[0.5rem]">
                    {errors.email}
                  </span>
                )}
              </div>

              <div className={`${styles.flexColNWJSIS} w-[45%] gap-[0.6rem] `}>
                <input
                  style={{ background: "rgba(0, 86, 60, 0.10)" }}
                  className="rounded-lg p-4 text-[0.8rem] w-[100%] "
                  placeholder="Organization Name"
                  name="organization"
                  value={formData.organization}
                  onChange={handleInputChange}
                />
                {errors.organization && (
                  <span className="text-[0.8rem] text-red-500 px-[0.5rem]">
                    {errors.organization}
                  </span>
                )}
              </div>

              <div className={`${styles.flexColNWJSIS} w-[45%] gap-[0.6rem] `}>
                <input
                  style={{ background: "rgba(0, 86, 60, 0.10)" }}
                  className="rounded-lg p-4 text-[0.8rem] w-[100%]"
                  placeholder="Contact No"
                  name="contact_no"
                  value={formData.contact_no}
                  onChange={handleInputChange}
                />
                {errors.contact_no && (
                  <span className="text-[0.8rem] text-red-500 px-[0.5rem]">
                    {errors.contact_no}
                  </span>
                )}
              </div>

              <div className={`${styles.flexColNWJSIS} gap-[0.8rem]`}>
                <p className="font-[500] text-[0.9rem] ">Select Services</p>
                <div className={`${styles.flexRowWJSIC} gap-[1rem] w-[100%]`}>
                  {allServices?.map((service) => (
                    <div
                      key={service.id}
                      className="flex items-center gap-[1rem]"
                    >
                      <input
                        type="checkbox"
                        id={`service-${service.id}`}
                        value={service.id}
                        onChange={handleCheckboxChange}
                        checked={formData.services.includes(String(service.id))}
                        className="cursor-pointer"
                      />
                      <label
                        htmlFor={`service-${service.id}`}
                        className="ml-1 text-[0.8rem] cursor-pointer"
                      >
                        {service.title}
                      </label>
                    </div>
                  ))}
                </div>
                {errors.services && (
                  <span className="text-[0.8rem] text-red-500">
                    {errors.services}
                  </span>
                )}
              </div>

              <textarea
                style={{ background: "rgba(0, 86, 60, 0.10)" }}
                className="rounded-lg p-4 w-[95%] text-[0.8rem]"
                placeholder="Drop your Comment"
                name="comment"
                value={formData.comment}
                onChange={handleInputChange}
              />

              <div className="w-[100%] flex justify-between gap-[1rem]">
                <div className="flex justify-center items-center w-[70%] lg:w-[70%]">
                  <Button
                    onClick={handleSubmit}
                    className="bg-[#00563C] w-full rounded-md flex-1  p-2 text-[#fff] text-center font-montserrat text-base font-medium  "
                  >
                    Send Message
                  </Button>
                </div>
                <button
                  className="w-[6rem] px-2 py-2 bg-gray-300 rounded-md my-1 mr-[1.4rem]"
                  onClick={() => setIsBookDemoFormOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        ) : (
          <div className="flex justify-center p-[1rem]"><Loader/></div>
        )}
      </div>
    </div>
  );
};

export default BookDemoPopup;
