import React from "react";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ScrollTrigger, gsap, useGSAP } from "../../../Constants";
import { productsData } from "../../../Constants/ProductPageConstants";
import { styles } from "../../../styles";
gsap.registerPlugin(ScrollTrigger);

const ProductCarousel = () => {
  useGSAP(() => {
    gsap.fromTo(
      "#productCarouselTitle",
      {
        opacity: 0,

        y: 20,
      },
      {
        duration: 1,
        ease: "power1.inOut",
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: "#productCarouselTitle",
        },
      }
    );
    gsap.fromTo(
      "#productCarouselSubtitle",
      {
        opacity: 0,
        y: 20,
      },
      {
        ease: "power1.inOut",
        opacity: 1,
        y: 0,
        delay: 1,
        stagger: 0.1,
        scrollTrigger: {
          trigger: "#productCarouselSubtitle",
        },
      }
    );
  }, []);

  return (
    <div className={`${styles.paddingX} overflow-hidden h-auto relative flex `}>
      <Swiper
        className="flex items-center flex-col justify-center"
        grabCursor={true}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        speed={1000}
        pagination={{
          clickable: true,
        }}
        style={{
          "--swiper-pagination-color": "#00563C",
          "--swiper-pagination-bullet-size": "10px",
          "--swiper-pagination-bullet-inactive-color": "#00563C",
          "--swiper-pagination-bullet-inactive-size": "6px",
          "--swiper-pagination-bullet-margin-top": "250px",
        }}
        modules={[Autoplay, Navigation, Pagination]}
      >
        {productsData?.map((product, index) => (
          <SwiperSlide key={index}>
            <div className="w-full flex lg:flex-row-reverse flex-row flex-wrap gap-[2rem] justify-between mt-[4rem] mb-[1.5rem] px-[4rem] xl:px-[6rem] ">
            <div
                  className={` ${
                    window.innerWidth < 1025
                      ? "flex justify-center"
                      : ""
                  } w-[100%] lg:w-[35%] `}
                >
                  <img
                    src={product?.image}
                    alt="iesg-ione"
                    className="w-[24rem]"
                  />
                </div>
                <div
                  className={`${
                    window.innerWidth < 429
                      ? styles.flexColNWJCIC
                      : styles.flexColNWJCIS
                  } w-[100%] lg:w-[40%] xl:w-[60%] `}
                >
                  <div
                    className={`mb-6 sm:mb-8 md:mb-10 w-full flex flex-col items-center lg:items-start justify-center `}
                  >
                    <p
                      id="productCarouselTitle"
                      className={`text-[3rem] min-[768px]:text-[4rem] min-[1120px]:text-[5rem] leading-tight font-[600]`}
                    >
                      {product?.title}
                    </p>
                    <p
                      id="productCarouselSubtitle"
                      className={`text-[2rem] font-[500] text-left`}
                    >{`${product?.subtitle}`}</p>
                  </div>
                  {product?.application?.length > 0 && (
                    <div
                      className={`${
                        window.innerWidth < 429 ? styles.flexColNWJSIS : ""
                      } `}
                    >
                      <p className="font-[700] text-[1.2rem] w-[40%] ">
                        Applications
                      </p>
                      <ul className={`p-[1rem] list-disc z-50`}>
                        {product?.application?.map((x, index) => {
                          return (
                            <li
                              key={index}
                              className="font-[500] text-[1.2rem] py-[0.6rem] "
                            >
                              {x}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                  <Link
                    className={` ${styles.flexRowNWJBIC} mt-[1rem] w-[12rem] z-[99999]`}
                    to={`/products/#${product?.id}`}
                  >
                    <p className="font-normal text-[1.3rem]">Read More</p>
                    <div
                      className={`w-[4rem] h-[2.2rem] bg-[#0F2229] ${styles.flexRowNWJCIC} cursor-pointer `}
                    >
                      <p className="text-center py-1 text-white text-xl md:text-2xl font-bold cursor-pointer ">
                        {">"}
                      </p>
                    </div>
                  </Link>
                </div>
                
              </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProductCarousel;
