import classNames from "classnames";
import React from "react";

const RadialGreenGradient = ({ children, className, ...rest }) => {
  const finalClass = classNames(
    className,
    "w-[24rem] md:w-[30rem] h-[30rem] z-[-1000] absolute"
  );
  return (
    <div
      className={finalClass}
      id="green-drop"
      // style={{
      //   // borderRadius: "625px",
      //   // background:
      //   //   "radial-gradient(50% 50% at 50% 50%, rgba(8, 184, 131, 0.34) 0%, rgba(0, 86, 60, 0.00) 100%)",
      //   // -----------------
      //   // background: "rgb(8,184,131)",
      //   // background: "radial-gradient(circle, rgba(8,184,131,1) 0%, rgba(255,255,255,1) 50%)",
      //   // borderRadius: "150px 0px 150px 0"
      //   // -------------------
      //   //       width: "100vh",
      //   // height: "100vh",
      //   // backgroundColor: "#000",
      //   // backgroundImage:
      //   //   "radial-gradient(circle 30vh at 100% 50%, rgba(8, 184, 131, 0.5), #0000)",
      //   // backgroundRepeat: "no-repeat",
      //   // zIndex: "-1000",
      // }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default RadialGreenGradient;
