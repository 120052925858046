import React, { useEffect, useRef, useState } from "react";
import { FaPlay } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { ScrollTrigger, gsap, useGSAP } from "../../../Constants";
import { productsData } from "../../../Constants/ProductPageConstants";
import { useGlobalContext } from "../../../context.js/useGlobalContext";
import { styles } from "../../../styles";
gsap.registerPlugin(ScrollTrigger);

const ProductHero = () => {
  const location = useLocation();
  const [active, setActive] = useState(
    location.hash.slice(1) || productsData?.[0]?.id
  );
  const [isScrolling, setIsScrolling] = useState(false);
  const { setIsBookDemoFormOpen } = useGlobalContext();
  const [animationAppliedArray, setAnimationAppliedArray] = useState([]);
  const productRef = useRef({});

  // When user clicks on any product link , this will help to scroll down to that particular product
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  // Used to update the isScrolling state
  window.addEventListener("scroll", () => {
    if (window.scrollY > 20) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  });

  useEffect(() => {
    const handleScroll = () => {
      const componentRects = Object.entries(productRef.current).map(
        ([key, ref]) => ({
          key,
          rect: ref.getBoundingClientRect(),
        })
      );
      // Find the key of the first component where at least half is visible
      const firstVisibleKey = componentRects.find(
        ({ rect }) =>
          rect.top >= 0 &&
          rect.bottom <= window.innerHeight &&
          rect.bottom - rect.height / 2 <= window.innerHeight
      )?.key;

      if (firstVisibleKey && firstVisibleKey !== active) {
        setActive(firstVisibleKey);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [active]);

  useGSAP(() => {
    !animationAppliedArray?.includes(active) &&
      gsap.fromTo(
        `#${active}-title`,
        {
          opacity: 0,
          y: 100,
        },
        {
          duration: 1,
          ease: "power2.inOut",
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: `#${active}-title`,
            once: true,
          },
        }
      );
    !animationAppliedArray?.includes(active) &&
      gsap.fromTo(
        `#${active}-application`,
        {
          opacity: 0,
          x: 100,
        },
        {
          duration: 1,
          ease: "power1.inOut",
          opacity: 1,
          x: 0,
          scrollTrigger: {
            trigger: `#${active}-application`,
            once: true,
          },
        }
      );
    !animationAppliedArray?.includes(active) &&
      gsap.fromTo(
        `#${active}-technical`,
        {
          opacity: 0,
          x: -100,
        },
        {
          duration: 1,
          ease: "power1.inOut",
          opacity: 1,
          x: 0,
          scrollTrigger: {
            trigger: `#${active}-technical`,
            once: true,
          },
        }
      );

    !animationAppliedArray?.includes(active) &&
      gsap.fromTo(
        `#${active}-black-vertical-line`,
        {
          height: 0,
        },
        {
          height: "20rem",
          duration: 3,
        }
      );

    setAnimationAppliedArray((prev) => [...prev, active]);
  }, [active]);

  return (
    <>
      <button
        className={`${
          isScrolling ? "scale-100 duration-500" : "scale-0 duration-500"
        } sticky top-[90%] left-[60%] md:left-[80%] lg:left-[80%] xl:left-[90%] text-white bg-[#00563C] px-[0.8rem] py-[0.4rem] rounded-[0.6rem] font-[500] cursor-pointer z-[999] `}
        onClick={() => window.scrollTo({ top: 0 })}
      >
        Go to Top
      </button>
      <div
        className={` ${
          window.innerWidth < 1024 ? styles.paddingX : ""
        } w-full ${
          window.innerWidth < 1024 ? styles.flexColNWJCIC : styles.flexRowNWJSIS
        } mx-auto relative lg:px-[2rem] `}
      >
        <div
          className={`${
            window.innerWidth < 1024
              ? styles.flexRowWJCIC
              : styles.flexColNWJCIC
          } static w-[100%] lg:w-[18%] xl:w-[13%] lg:sticky top-[5%] z-[999] gap-[3rem] p-[1.6rem] bg-[#fff] ${
            isScrolling && window.innerWidth < 1024
              ? " scale-0 duration-700 "
              : "scale-100 duration-700"
          }   transition-all duration-700 `}
        >
          {productsData?.map((item, index) => (
            <a href={`#${item?.id}`} className="cursor-pointer" key={index}>
              <div
                className={`${
                  styles.flexColNWJCIC
                } cursor-pointer hover:scale-125 ${
                  active === item?.id ? "scale-125" : "scale-100"
                } duration-700 w-[8rem] `}
              >
                <img
                  src={item?.logo}
                  alt={item?.title}
                  className={`h-[3.5rem] duration-500 mb-[0.2rem] `}
                />
                <span className={`font-semibold text-[0.9rem] text-center `}>
                  {item?.title}
                </span>
                <span
                  className={`font-semibold text-[0.7rem] mb-[0.4rem] text-center `}
                >
                  {item?.subtitle}
                </span>
                {active === item?.id ? (
                  <div
                    style={{
                      background:
                        "linear-gradient(270deg, #B3DC27 0%, #18AF52 100%)",
                    }}
                    className={`h-[0.2rem] w-[6rem] rounded-[0.4rem]`}
                  ></div>
                ) : null}
              </div>
            </a>
          ))}
        </div>

        <div
          className={` ${styles.flexColNWJSIS} ${styles.paddingX} w-full gap-[4rem] mb-[2rem]`}
        >
          {productsData?.map((item, index) => (
            <div
              id={item?.id}
              key={index}
              className={`${styles.flexColNWJSIS} gap-[2rem] w-full  `}
            >
              <div
                id={`${item?.id}-title`}
                className={`${styles.flexColNWJCIC} mb-[2rem] ${
                  animationAppliedArray?.includes(item?.id)
                    ? "opacity-100"
                    : "opacity-0 "
                } w-full `}
                ref={(ref) => (productRef.current[`${item?.id}`] = ref)}
              >
                <p
                  className={`text-[3rem] md:text-[4rem] leading-tight font-[600] `}
                >
                  {item?.title}
                </p>
                <p className={`text-[1rem] md:text-[2rem] font-[500]`}>
                  {item?.subtitle}
                </p>
              </div>
              <div className={`flex flex-wrap justify-start items-start gap-[2rem] lg:gap-[4rem] w-full `}>
               <div className="flex justify-center items-center w-[100%] lg:w-[40%] " >
                 <img
                  id={`${item?.id}-image`}
                  src={item?.image}
                  alt={item?.title}
                  className="h-[26rem] md:h-[20rem] lg:h-[26rem] z-[1]"
                  ref={(ref) => (productRef.current[`${item?.id}`] = ref)}
                />
               </div>
                <div
                  id={`${item?.id}-black-vertical-line`}
                  className={`h-[24rem] w-[1px] bg-black hidden lg:block ${
                    animationAppliedArray?.includes(item?.id)
                      ? "opacity-100"
                      : "opacity-0 "
                  } `}
                />
                <div
                  className={`${styles.flexColNWJSIS}  lg:w-[25%] xl:w-[28%] gap-[1rem]`}
                >
                  <div
                    id={`${item?.id}-application`}
                    className={`${
                      animationAppliedArray?.includes(item?.id)
                        ? "opacity-100"
                        : "opacity-0 "
                    } `}
                  >
                    {item?.technical_specifications?.length > 0 && (
                      <div>
                        <p className="font-[700] text-[1.2rem] ">
                          Technical Product Specifications
                        </p>
                        <ul className={`p-[1rem] list-disc z-50`}>
                          {item?.technical_specifications?.map((x, index) => {
                            return (
                              <li
                                key={index}
                                className="font-[500] text-[0.9rem] py-[0.4rem]"
                              >
                                {x}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                    {item?.application?.length > 0 && (
                      <div>
                        <p className="font-[700] text-[1.2rem] w-[40%] ">
                          Application
                        </p>
                        <ul className={`p-[1rem] list-disc z-50`}>
                          {item?.application?.map((x, index) => {
                            return (
                              <li
                                key={index}
                                className="font-[500] text-[0.9rem] py-[0.4rem] "
                              >
                                {x}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                  <button
                    // to={`/about/#book-a-demo`}
                    className={`w-[10rem] ${styles.flexRowNWJBIC} bg-[#0F2229] rounded-md`}
                    onClick={() => setIsBookDemoFormOpen(true)}
                  >
                    <p className="p-2 text-[0.8rem] text-[#fff] font-[600] ">
                      Book A Demo
                    </p>
                    <FaPlay className="text-[#fff] w-[1.4rem] mr-[0.5rem]" />
                  </button>
                </div>
              </div>
              {item?.description?.length > 0 && (
                <div
                  id={`${item?.id}-technical`}
                  className={`${styles.flexColNWJSIS} ${
                    animationAppliedArray?.includes(item?.id)
                      ? "opacity-100"
                      : "opacity-0 "
                  } gap-[1rem] w-full`}
                >
                  <p className="font-[600] text-[1.2rem] ">Description</p>
                  <ul className={`p-[1rem] list-disc z-50 flex flex-col ${item?.id === "iot-panel" || item?.id === "annunciator" ? "lg:flex-col" : "lg:flex-row"} flex-wrap w-full gap-[0.5rem] ${item?.id === "iot-panel" || item?.id === "annunciator" ? "lg:gap-[0.5rem]" : "lg:gap-[4rem]" } `}>
                    {item?.description?.map((x, index) => {
                      return (
                        <li key={index}>
                          <p className="font-[500] text-[0.9rem] py-[0.4rem] ">
                            {x?.name}
                          </p>
                          {x?.subpoints?.length > 0 && (
                            <ul className={`p-[1rem] list-disc z-50`}>
                              {x?.subpoints?.map((y, index) => {
                                return (
                                  <li
                                    key={index}
                                    className="font-[500] text-[0.8rem] py-[0.4rem] "
                                  >
                                    {y?.subPointName}
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                  <p className="font-[500] text-[0.9rem] py-[0.4rem] leading-loose ">{item?.detailed_description}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ProductHero;
