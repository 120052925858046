import React, { useEffect, useState } from "react";
import { styles } from "../../../styles";
import Button from "../../ReusableComponents/Button";
import { contact_us_page_image, polygon_first, polygon_second } from "../../../Assets";
import RadialGreenGradient from "../../ReusableComponents/RadialGreenGradiant";
import { servicesData } from "../../../Constants/ServicePageConstants";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";
import { gsap, useGSAP, ScrollTrigger } from "../../../Constants";
import Loader from "../../ReusableComponents/Loader";
import { VscTriangleUp } from "react-icons/vsc";
gsap.registerPlugin(ScrollTrigger);

const ContactUsForm = () => {
  const location = useLocation();

  useGSAP(() => {
    gsap.fromTo(
      "#book-demo-form",
      {
        opacity: 0,
        x: -100,
      },
      {
        duration: 1,
        ease: "power1.inOut",
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: `#book-demo-form`,
        },
      }
    );
    gsap.fromTo(
      "#book-demo-image",
      {
        opacity: 0,
        x: 100,
      },
      {
        duration: 1,
        ease: "power1.inOut",
        opacity: 1,
        x: 0,
        scrollTrigger: {
          trigger: `#book-demo-image`,
        },
      }
    );
  }, []);

  const [formData, setFormData] = useState({
    user_name: "",
    email: "",
    organization: "",
    contact_no: "",
    comment: "",
  });
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};
    if (!formData.user_name) {
      newErrors.user_name = "Name is required";
      valid = false;
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
      valid = false;
    }

    if (!validateEmail(formData?.email)) {
      newErrors.email = "Enter Valid Email";
      valid = false;
    }

    if (!formData.organization) {
      newErrors.organization = "Organization name is required";
      valid = false;
    }

    if (!formData.contact_no) {
      newErrors.contact_no = "Contact number is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      axios
        .post("/iesg-website/book-demo", formData)
        .then((response) => {
          console.log(response?.data?.data);
          toast.success("Form submitted successfully");
          setFormData({
            user_name: "",
            email: "",
            organization: "",
            contact_no: "",
            services: [],
            comment: "",
          });
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
      setLoading(false);
      // const response = await fetch("your-api-endpoint", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(formData),
      // });
      // if (response.ok) {
      //   toast.success("Form submitted successfully");
      // } else {
      //   toast.error("Something went wrong");
      // }
    }
  };

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <div className={`${styles.paddingX} py-4 w-full mx-auto relative`}>
      <RadialGreenGradient
        className={"top-[25%]"}
        style={{
          backgroundImage:
            "radial-gradient(circle 10rem at 50% 40%, rgba(8, 184, 131, 0.4), #0000)",
        }}
      />
      <div
        className={`${styles.flexRowWJCIC}`}
        style={{ zIndex: "2000" }}
        id="book-a-demo"
      >
        {!loading ? (
          <div className="flex flex-col items-end justify-end ">
             <img id="book-demo-image" src={polygon_first} alt="" className="w-[2rem] object-contain lg:flex hidden " />
            <form
              id="book-demo-form"
              style={{
                background:
                  "linear-gradient(270deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%)",
                boxShadow: "0px 4px 11.4px 1px rgba(151, 151, 151, 0.25)",
              }}
              onSubmit={handleSubmit}
              className={`bg-[#fff] p-4 lg:p-8 w-[280px] sm:w-[300px] md:w-[340px] lg:w-[550px] xl:w-[550px] 2xl:w-[580px] ${styles.flexColNWJCIS}`}
            >
              <p className="uppercase font-bold text-[#4A4A4A] text-[1.6rem] mb-2">
                Contact Us
              </p>
              <p className="font-normal text-[0.8rem] mb-4">
                By submitting this form, I consent to the processing of the
                personal data that I provide to IESG Pvt. Ltd. in accordance
                with and as described in the Privacy Policy. Refund Policy,
                Terms and Condition
              </p>
              <div className={`${styles.flexRowWJSIC} gap-4 lg:gap-6`}>
                <div
                  className={`${styles.flexColNWJSIS} w-[45%] gap-[0.6rem] `}
                >
                  <input
                    style={{ background: "rgba(0, 86, 60, 0.10)" }}
                    className="rounded-lg p-4 text-[0.8rem] w-[100%] "
                    placeholder="Enter Your Name"
                    name="user_name"
                    value={formData.user_name}
                    onChange={handleInputChange}
                  />
                  {errors.user_name && (
                    <span className="text-[0.8rem] text-red-500 px-[0.5rem]">
                      {errors.user_name}
                    </span>
                  )}
                </div>

                <div
                  className={`${styles.flexColNWJSIS} w-[45%] gap-[0.6rem] `}
                >
                  <input
                    style={{ background: "rgba(0, 86, 60, 0.10)" }}
                    className="rounded-lg p-4 text-[0.8rem]  w-[100%] "
                    placeholder="Drop Your Email ID Here"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {errors.email && (
                    <span className="text-[0.8rem] text-red-500 px-[0.5rem]">
                      {errors.email}
                    </span>
                  )}
                </div>

                <div
                  className={`${styles.flexColNWJSIS} w-[45%] gap-[0.6rem] `}
                >
                  <input
                    style={{ background: "rgba(0, 86, 60, 0.10)" }}
                    className="rounded-lg p-4 text-[0.8rem] w-[100%] "
                    placeholder="Organization Name"
                    name="organization"
                    value={formData.organization}
                    onChange={handleInputChange}
                  />
                  {errors.organization && (
                    <span className="text-[0.8rem] text-red-500 px-[0.5rem]">
                      {errors.organization}
                    </span>
                  )}
                </div>

                <div
                  className={`${styles.flexColNWJSIS} w-[45%] gap-[0.6rem] `}
                >
                  <input
                    style={{ background: "rgba(0, 86, 60, 0.10)" }}
                    className="rounded-lg p-4 text-[0.8rem] w-[100%]"
                    placeholder="Contact No"
                    name="contact_no"
                    value={formData.contact_no}
                    onChange={handleInputChange}
                  />
                  {errors.contact_no && (
                    <span className="text-[0.8rem] text-red-500 px-[0.5rem]">
                      {errors.contact_no}
                    </span>
                  )}
                </div>
                <textarea
                  style={{ background: "rgba(0, 86, 60, 0.10)" }}
                  className="rounded-lg p-4 w-[95%] text-[0.8rem]"
                  placeholder="Drop your Comment"
                  name="comment"
                  value={formData.comment}
                  onChange={handleInputChange}
                />

                <div className="w-[100%] flex justify-center">
                  <Button
                    onClick={handleSubmit}
                    className="bg-[#00563C] rounded-md p-2 text-[#fff] text-center font-montserrat text-base font-medium w-[40%]"
                  >
                    Send Message
                  </Button>
                </div>
              </div>
            </form>
             <img id="book-demo-image" src={polygon_second} alt="" className="w-[2rem] object-contain lg:flex hidden" />
          </div>
        ) : (
          <div className="flex justify-center p-[1rem]">
            <Loader />
          </div>
        )}

        <div
          className={` w-[16rem] lg:w-[18rem] xl:w-[22rem] h-[16rem] lg:h-[33rem] ${styles.flexRowWJCIC}  `}
          style={{ background: "rgba(0, 86, 60, 0.10)", zIndex: "2000" }}
          id="book-demo-image"
        >
          <img
            src={contact_us_page_image}
            alt="contact_page_image"
            className="w-full h-full"
          />
        </div>
      </div>
      {/* <RadialGreenGradient
        className={"top-[25%] right-[10%] -z-50"}
        style={{
          backgroundImage:
            "radial-gradient(circle 10rem at 50% 50%, rgba(8, 184, 131, 0.4), #0000)",
        }}
      /> */}
    </div>
  );
};

export default ContactUsForm;
