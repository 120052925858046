import {
  device_logo,
  gateway_logo,
  interface_logo,
  internet_logo,
  server_logo,
  sustain_analytics_img,
  sustain_analytics_logo,
  sustain_connect_img,
  sustain_connect_logo,
  sustain_sphere_logo,
  sustainshere_img,
  watt_wise_logo,
  wattwise_img,
} from "../Assets";

export const servicesData = [
  {
    id: "platform-services",
    label: "Platform Services",
    services: [
      {
        id: "sustain-sphere",
        title: "SustainSphere",
        subtitle: "(Enterprise Application Services)",
        logo: sustain_sphere_logo,
        image: sustain_sphere_logo,
        description:
          "We redefine the way you approach sustainability with our comprehensive features designed to optimize energy consumption, reduce costs, and minimize environmental impact. With a focus on smart asset monitoring and control, SustainSphere seamlessly connects to a diverse range of assets, providing valuable data on a single platform. We connect, monitor, control and report.",
        utilityProp: [
          "UTILITY MONITORING",
          "UTILITY REPORT",
          "ALERT MONITORING",
          "CONTROLING",
        ],
        platform_components: [
          {
            label: "Device Management",
            description: "",
            points: [
              "IoT device management is the process of authenticating, provisioning, configuring, monitoring, and maintaining the device firmware and software that provides its functional capabilities.",
              "Devices management is one of the most critical aspects of the IoT platform as it provides scalability and security for multiple devices to connect concurrently.",
            ],
          },
          {
            label: "Data Storage",
            description:
              "The SustainSphere IoT platform offers tremendous flexibility in data storage.",
            points: [
              "IAM storage can be bundled with the platform and provided in an ‘as a service’ model. The data is stored in a SOC certified data center.",
            ],
          },
          {
            label: "Applications",
            description:
              "The SustainSphere IoT Platform comes with a fully configurable application. The user can complete the whole process of configuring devices to monitor live data in a few minute.",
            points: [
              "Multiple Utility Support",
              "Dashboards for insights",
              "Multi-level hierarchy",
              "Live performance monitoring",
              "Report builder support",
              "AMC Management",
              "Bill Management",
              "Graphical analysis",
              "Standard templates for energy management",
              "Alerts and warnings",
            ],
          },
          {
            label: "Analytics",
            description:
              "Our data analytics team can provide analytical insights as a service. Include data from various sources, including resources. Analytics, you get",
            points: [
              "Conversion of big data into revenue",
              "Insights on future trends",
              "Predictive maintenance",
              "Increase in Equipment Uptime",
              "Enhanced customer experience",
              "Reduction in Maintenance Cost",
            ],
          },
          {
            label: "Integration",
            description:
              "The SustainSphere platform integrates with ERP system.",
            points: [],
          },
        ],
      },
      {
        id: "watt-wise",
        title: "Watt-Wise",
        subtitle: "(Monitoring Services)",
        logo: watt_wise_logo,
        image: watt_wise_logo,
        description:
          "Energy Monitoring Cloud Solutions. Track energy consumption at the device level, giving you unprecedented visibility into your operations. Measure and reduce your carbon footprint with our environmental impact assessment tools. Make sustainability a core part of your business strategy while contributing to a greener future.",
        utilityProp: [
          "UTILITY MONITORING",
          "UTILITY REPORT",
          "ENERGY MONITORING",
        ],
        platform_components: [
          {
            label: "Applications",
            description:
              "The IoT Monitoring Platform comes with a fully configurable application. The user can complete the whole process of configuring devices to monitor data in realtime.",
            points: [
              "Dashboards for insights",
              "Live performance monitoring",
              "Report building support",
              "Graphical analysis",
              "Standard templates for energy management",
              "Alerts and warnings",
            ],
          },
        ],
      },
      {
        id: "sustain-analytics",
        title: "SustainAnalytics",
        subtitle: "(Data Analytics)",
        logo: sustain_analytics_logo,
        image: sustain_analytics_logo,
        description:
          "Detect and address energy anomalies before they impact your bottom line with IESG Labs' anomaly reporting capabilities. Our dedicated data teams identify deviations from normal energy consumption patterns, enabling swift corrective action. Identify potential equipment issues early on, preventing costly downtime and repairs. Receive real-time alerts tailored to your preferences, allowing you to stay informed and in control.",

        utilityProp: ["UTILITY REPORT"],
        platform_components: [
          {
            label: "Analytics",
            description:
              "SustainAnalytics is analytical insight as a Service . We have a dedicated data analytics team to measure and report your anomalies and trends . Include data from various resources. With SustainAnalytics, you get",
            points: [
              "Conversion of big data into revenue",
              "Insights on future trends",
              "Predictive maintenance",
              "Increase in Equipment Uptime",
              "Enhanced customer experience",
              "Reduction in Maintenance Cost",
            ],
          },
          {
            label: "Integration",
            description:
              "The SustainAnalytics platform integrates with ERP system.",
            points: [],
          },
        ],
      },
    ],
  },
  {
    id: "hardware-services",
    label: "Hardware Services",
    services: [
      {
        id: "sustain-connect",
        title: "SustainConnect",
        subtitle: "(Hardware)",
        logo: sustain_connect_logo,
        image: sustain_connect_logo,
        description:
          "In House designed, manufactured and quality assured hardware. IoT gateway (iONE) to connect your assets and send the data over cloud. IO Panels to indicate the MCB trip status. Wireless IR Control Hubs to control your ACs and VRF wirelessly. Temperature sensors to provide the actual feel temperature.",
        utilityProp: [],
        platform_components: [],
      },
    ],
  },
];

export const solutionArchitectureData = [
  {
    label: "Device",
    logo: device_logo,
  },
  {
    label: "Gateway",
    logo: gateway_logo,
  },
  {
    label: "Internet",
    logo: internet_logo,
  },
  {
    label: "Server",
    logo: server_logo,
  },
  {
    label: "Interface",
    logo: interface_logo,
  },
];

export const allServices = [
  {
    id: "sustain-sphere",
    title: "SustainSphere",
  },
  {
    id: "sustain-connect",
    title: "SustainConnect",
  },
  {
    id: "watt-wise",
    title: "Watt-Wise",
  },
  {
    id: "sustain-analytics",
    title: "SustainAnalytics",
  }
];
