import React from 'react'
import { cmsPartnersData } from '../../../Constants/HomePageConstants'
import { styles } from '../../../styles'

const CmsParterns = ({id}) => {
  return (
     <div
        id={id}
        className={`${styles.flexColNWJCIC} p-[2rem] w-[100%] md:w-[30%] lg:w-[20%] 2xl:w-[40%]`}
      >
        <p className={`text-[#4A4A4A] font-[600] uppercase text-center text-[1.2rem]`}>
          CMS <br/> PARTNERS
        </p>
        <div className="">
          {cmsPartnersData?.map((partner, index) => (
            <div key={index} className={`p-[2rem]`}>
              <img
                src={partner?.company_logo}
                alt={partner?.company_name}
                className="h-[5rem] "
              />
            </div>
          ))}
          
        </div>
      </div> 
  )
}

export default CmsParterns
