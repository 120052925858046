import React from 'react'
import CareerComponent from '../Components/SubComponents/Career/CareerComponent'

const CareerPage = () => {
  return (
    <div>
      <CareerComponent/>
    </div>
  )
}

export default CareerPage
