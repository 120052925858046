import React, { useRef } from "react";
import { styles } from "../../../styles";
import { arrows_list, dashboard_options_list, iot_bg } from "../../../Assets";
import {
  empoweringSustaibilityDescription,
  empoweringSustaibilityListData,
  measuringManagingReportingDescription,
  gsap,
  useGSAP,
  ScrollTrigger
} from "../../../Constants";
gsap.registerPlugin(ScrollTrigger);

const EmpoweringSustainability = () => {
  useGSAP(() => {
    gsap.fromTo(
      "#empoweringImage",
      {
        opacity: 0,
        scale: 0,
      },
      {
        ease: "power2.inOut",
        opacity: 1,
        duration: 1,
        scale: 1,
        scrollTrigger: {
          trigger: "#empoweringImage",
        },
      }
    );

    gsap.fromTo(
      "#empoweringSustainabilityTitle",
      {
        opacity: 0,
        x: -150,
      },
      {
        ease: "power2.inOut",
        opacity: 1,
        duration: 1,
        x: 0,
        scrollTrigger: {
          trigger: "#empoweringSustainabilityTitle",
        },
      }
    );

    gsap.fromTo(
      "#listandArrow",
      {
        opacity: 0,
        x: -250,
      },
      {
        ease: "power1.inOut",
        opacity: 1,
        scale: 1,
        delay: 0.75,
        duration: 1,
        x: 0,
        scrollTrigger: {
          trigger: "#measuringManagingReporting",
        },
      }
    );
    gsap.fromTo(
      "#measuringManagingReporting",
      {
        opacity: 0,
        x: 250,
      },
      {
        ease: "power1.inOut",
        opacity: 1,
        scale: 1,
        delay: 0.75,
        duration: 1,
        x: 0,
        scrollTrigger: {
          trigger: "#measuringManagingReporting",
        },
      }
    );
    gsap.fromTo(
      "#emporweringSustainabilityDescription",
      {
        opacity: 0,
        y: 20,
      },
      {
        ease: "power1.inOut",
        opacity: 1,
        duration: 1,
        y: 0,
        delay: 0.75,
        scrollTrigger: {
          trigger: "#emporweringSustainabilityDescription",
        },
      }
    );
  }, []);

  return (
    <div className={` ${styles.flexColNWJCIC} ${styles.paddingX}`}>
      <div className={`relative ${styles.flexRowWJCIC} w-[100%] text-center`}>
        <img
          id="empoweringImage"
          src={iot_bg}
          alt="iot_bg"
          className="w-[20rem] 2xl:w-[30rem] "
        />
        <p
          id="empoweringSustainabilityTitle"
          className="absolute uppercase font-semibold text-[2.2rem]"
          style={{ zIndex: "1000" }}
        >
          Empowering Sustainability
        </p>
      </div>
      <p
        id="emporweringSustainabilityDescription"
        className={`${styles.heroSubText} text-[1.3rem] text-center`}
      >
        {empoweringSustaibilityDescription}
      </p>

      <div className={`${styles.flexRowNWJBIC} ${styles.marginTop}`}>
        {/* <div className="hidden md:flex flex-col bg-[#00563C] p-2 md:p-4 w-[100px] h-auto">
          {empoweringSustaibilityListData?.map((item, index) => (
            <div
              key={index}
              className="flex flex-col justify-center items-center p-2"
            >
              <img
                src={item?.logo}
                alt={item?.logo}
                className="w-[40px] h-[40px]"
              />
              <p className="text-center">{item?.name}</p>
            </div>
          ))}
        </div> */}
        <div className={`${styles.flexRowNWJCIC}`} id="listandArrow">
          <img
            src={dashboard_options_list}
            alt="dashboard_options_list"
            className="w-[7rem]"
          />
          <img src={arrows_list} alt="arrows_list" className="hidden md:flex" />
        </div>
        <div
          id="measuringManagingReporting"
          className={`w-[22rem] xl:w-[45rem] p-[2rem] h-auto rounded-[1rem] ${styles.flexColNWJSIS}`}
          style={{
            background:
              "linear-gradient(90deg, #D7E4EE 9.04%, rgba(255, 255, 255, 0.00) 100%)",
          }}
        >
          <p className="text-[#00563C] font-semibold text-[1.6rem] my-[1rem]">
            Measuring | Managing | Reporting
          </p>
          <p className="font-normal text-[1.3rem] my-[1rem]">
            {measuringManagingReportingDescription}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmpoweringSustainability;
