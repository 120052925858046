import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./index.css";
import axios from "axios";
import GlobalContextProvider from "./context.js/useGlobalContext";

// axios.defaults.baseURL = 'http://10.129.2.27:8098';

axios.defaults.baseURL = "https://iesglabs.com/api/";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <GlobalContextProvider>
      <App />
    </GlobalContextProvider>
  </React.StrictMode>
);
