import {
  amol_sir_pic,
  hitesh_chemburkar_sir_pic,
  linkedin_logo,
  pankaj_zanwar_sir_pic,
  pratik_anekar_sir_pic,
  saurabh_khatmode_sir_pic,
  shital_zanwar_mam_pic,
  sumeet_tanpure_sir_pic,
} from "../Assets";

export const aboutHeroTitle =
  "We Drive Sustainable Business Engineering For A Greener Tomorrow.";
export const aboutHeroDescription =
  "At iESG Labs pvt ltd., we believe in harnessing the power of technology to drive positive change and build a sustainable future. If you are passionate about making a meaningful impact and want to be part of a team that is at the forefront of sustainability technology, you've come to the right place!";

export const approachFieldsData = [
  {
    id: "iot-energy",
    title: "IOT - Energy",
  },
  {
    id: "iot-sustainability",
    title: "IOT - Sustainability",
  },
  {
    id: "iot-governance",
    title: "IOT - Governance",
  },
];
export const approachDescription =
  "At IESG Labs Pvt Ltd, our approach is grounded in a commitment to fostering a sustainable and brighter future. We believe in empowering the youth, recognizing their potential as catalysts for positive change. Our mission is to provide a platform where innovation and sustainability converge, guiding the next generation to make a meaningful impact. Through mentorship, education, and opportunities, we aim to shape leaders who not only envision a better world but actively contribute to its sustainability. At the core of our philosophy is the belief that investing in the youth today will pave the way for a more resilient and eco-conscious tomorrow.";

export const teamInfoTitle = "The Company That Invented Service Platform";

export const boardMembersInfo = [
  {
    name: "Shital Zanwar",
    designation: "Managing Director",
    profile_photo: shital_zanwar_mam_pic,
    social_media_info: [],
  },
  {
    name: "Hitesh Chemburkar",
    designation: "Director",
    profile_photo: hitesh_chemburkar_sir_pic,
    social_media_info: [
      {
        platformName: "LinkedIn",
        platformLogo: linkedin_logo,
        platformLink: "https://in.linkedin.com/in/hitesh-chemburkar-982600a4",
      },
    ],
  },
  {
    name: "Pankaj Zanwar",
    designation: "Advisor",
    profile_photo: pankaj_zanwar_sir_pic,
    social_media_info: [
      {
        platformName: "LinkedIn",
        platformLogo: linkedin_logo,
        platformLink: "https://in.linkedin.com/in/pankajzanwar",
      },
    ],
  },
];

export const teamInfoDescription =
  '"At IESG Labs Pvt Ltd, our approach is grounded in a commitment to fostering a sustainable and brighter future. We believe in empowering the youth, recognizing their potential as catalysts for positive change. Our mission is to provide a platform where innovation and sustainability converge, guiding the next generation to make a meaningful impact. Through mentorship, education, and opportunities, we aim to shape leaders who not only envision a better world but actively contribute to its sustainability. At the core of our philosophy is the belief that investing in the youth today will pave the way for a more resilient and eco-conscious tomorrow."';

export const coreTeamMembersInfo = [
  // {
  //   name : "Sumeet Tanpure",
  //   designation : "Full Stack Developer",
  //   profile_photo : sumeet_tanpure_sir_pic,
  //   social_media_info : [
  //     {
  //       platformName : "LinkedIn",
  //       platformLogo : linkedin_logo,
  //       platformLink : "",
  //     }
  //   ]
  // },
  // {
  //   name : "Pratik Anekar",
  //   designation : "Devops Engineer",
  //   profile_photo : pratik_anekar_sir_pic,
  //   social_media_info : [
  //     {
  //       platformName : "LinkedIn",
  //       platformLogo : linkedin_logo,
  //       platformLink : "",
  //     }
  //   ]
  // },
  // {
  //   name : "Saurabh Khatmode",
  //   designation : "Full Stack Developer",
  //   profile_photo : saurabh_khatmode_sir_pic,
  //   social_media_info : [
  //     {
  //       platformName : "LinkedIn",
  //       platformLogo : linkedin_logo,
  //       platformLink : "",
  //     }
  //   ]
  // },
  // {
  //   name : "Amol",
  //   designation : "Full Stack Developer",
  //   profile_photo : amol_sir_pic,
  //   social_media_info : [
  //     {
  //       platformName : "LinkedIn",
  //       platformLogo : linkedin_logo,
  //       platformLink : "",
  //     }
  //   ]
  // },
];
