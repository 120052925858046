import React from "react";
import {
  technologyPartnersData
} from "../../../Constants/HomePageConstants";
import { styles } from "../../../styles";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  Autoplay
} from "swiper/modules";

const TechnologyPartners = ({id}) => {
  return (
    
     
      <div
        id={id}
        className={`${styles.flexColNWJCIC} p-[2rem] w-[100%] md:w-[60%] lg:w-[20%] 2xl:w-[40%]`}
      >
        <p className={`text-[#4A4A4A] font-[600] uppercase text-center text-[1.2rem]`}>
          TECHNOLOGY <br/> PARTNERS
        </p>
        {/* <Marquee
          autoFill
          pauseOnHover
          gradient
          gradientColor={[9, 11, 43]}
          gradientWidth={100}
          className="mt-[1.2rem]"
        >
          {technologyPartnersData?.map((partner, index) => (
            <div key={index} className={`p-[2rem]`}>
              <img
                src={partner?.company_logo}
                alt={partner?.company_name}
                className="h-[6rem] "
              />
            </div>
          ))}
        // </Marquee> */}
        <Swiper
          grabCursor={true}
          loop={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          speed={1000}
          breakpoints={{
            0: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            400: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            639: {
              slidesPerView: 2,
              slidesPerGroup: 1,
            },
            865: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            1000: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            1500: {
              slidesPerView: 2,
              slidesPerGroup: 1,
            },
            1700: {
              slidesPerView: 2,
              slidesPerGroup: 1,
            },
          }}
          // spaceBetween={30}
          modules={[Autoplay]}
          className="w-full"
        >
          {technologyPartnersData?.map((partner, index) => (
            <SwiperSlide key={index}>
              <div
                className={`flex flex-row justify-center items-center p-[2rem] py-[2.4rem] `}
              >
                <img
                  src={partner?.company_logo}
                  alt={partner?.company_name}
                  className="h-[5rem] "
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      
      
  
  );
};

export default TechnologyPartners;
