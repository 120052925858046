import {
  homeHeroTitle,
  homeHeroSubTitle,
  allDashboardData,
  measuringManagingReportingDescription,
  empoweringSustaibilityDescription,
  empoweringSustaibilityListData,
  companyGrowthFigures,
  iotSolutionsListData,
  iesgOneDescription,
  iesgOneTitle,
  valuedByCompaniesData,
  endorsementsByCompaniesData,
  integrationPartnersData,
} from "./HomePageConstants";
import {
  careerHeroTitle,
  careerHeroSubtitle,
  whyChooseIesgData,
  currentOpeningsData,
} from "./CareerPageConstants";
import {
  aboutHeroDescription,
  aboutHeroTitle,
  approachDescription,
  approachFieldsData,
} from "./AboutPageConstants";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/all";

export const navLinks = [
  {
    id: "/",
    title: "Home",
    dropDownMenu : []
  },
  {
    id: "products",
    title: "Products",
    dropDownMenu: []
  },
  {
    id: "services",
    title: "Services",
    dropDownMenu : [
      {
        id : "platform-services",
        title : "Platform Services"
      },
      {
        id : "hardware-services",
        title : "Hardware Services"
      },
    ]
  },
  {
    id: "career",
    title: "Career",
    dropDownMenu: []
  },
  {
    id: "about",
    title: "About",
    dropDownMenu: []
  },
];

export const navLinkButtons = [
  {
    id: "book-a-demo",
    title: "Book a Demo",
  },
];

export const footerLinks = [
  {
    id: "products",
    title: "Products",
    sublinks: [
      {
        sublinkid: "iesg-ione",
        sublinktitle: "IESG-iONE",
      },
      {
        sublinkid: "ir-blaster",
        sublinktitle: "IR Control Hub",
      },
      {
        sublinkid: "temperature-device",
        sublinktitle: "Temperature Device",
      },
      {
        sublinkid: "iot-panel",
        sublinktitle: "IOT Panel",
      },
    ],
  },
  {
    id: "services",
    title: "Services",
    sublinks: [
      {
        serviceType: {
          id : "platform-services",
          label : "Platform Services"
       },
        sublinkid: "sustain-sphere",
        sublinktitle: "SustainSphere",
      },
      {
        serviceType: {
          id : "platform-services",
          label : "Platform Services"
       },
        sublinkid: "watt-wise",
        sublinktitle: "Watt-Wise",
      },
      {
        serviceType: {
          id : "platform-services",
          label : "Platform Services"
       },
        sublinkid: "sustain-analytics",
        sublinktitle: "SustainAnalytics",
      },
      {
        serviceType: {
          id : "hardware-services",
          label : "Hardware Services"
       },
        sublinkid: "sustain-connect",
        sublinktitle: "SustainConnect",
      },
    ],
  },
  // {
  //   id: "address",
  //   title: "Address",
  //   sublinks: [
  //     {
  //       sublinkid:
  //         "PL NO.27 ELECTRONIC CO-OP ESTATE, PUNE SATARA ROAD Parvati Pune City Pune MH 411009 IN.          ",
  //       sublinktitle:
  //         "PL NO.27 ELECTRONIC CO-OP ESTATE, PUNE SATARA ROAD Parvati Pune City Pune MH 411009 IN.          ",
  //     },
  //   ],
  // },
  // {
  //   id: "contact",
  //   title: "Contact",
  //   sublinks: [
  //     {
  //       sublinkid: "91-020-67479668",
  //       sublinktitle: "91-020-67479668",
  //     },
  //     {
  //       sublinkid: "91-020-67479668",
  //       sublinktitle: "91-020-67479668",
  //     },
  //   ],
  // },
  // {
  //   id: "mail",
  //   title: "Mail",
  //   sublinks: [
  //     {
  //       sublinkid: "enquiry@iesglabs.com",
  //       sublinktitle: "enquiry@iesglabs.com",
  //     },
  //   ],
  // },
];

export const address =
  "2ND FLOOR, BLOCK NO 27, ELECTRONIC CO-OP ESTATE, SATARA ROAD, SWARGATE, PUNE – 411009";
  export const addressLink = "https://maps.app.goo.gl/kHsEVx9qjaRgptxL9"
export const mail = [
  {
    id: "enquiry@iesglabs.com",
    mailId: "enquiry@iesglabs.com",
  },
];
export const contact = [
  {
    id: "+91 7420850478",
    mobileNo: "+91 7420850478",
  },
];

export {
  homeHeroTitle,
  homeHeroSubTitle,
  careerHeroTitle,
  careerHeroSubtitle,
  aboutHeroTitle,
  aboutHeroDescription,
  allDashboardData,
  measuringManagingReportingDescription,
  empoweringSustaibilityDescription,
  empoweringSustaibilityListData,
  companyGrowthFigures,
  iotSolutionsListData,
  iesgOneDescription,
  iesgOneTitle,
  valuedByCompaniesData,
  endorsementsByCompaniesData,
  integrationPartnersData,
  whyChooseIesgData,
  currentOpeningsData,
  approachFieldsData,
  approachDescription,
  gsap,
  useGSAP,
  ScrollTrigger
};
