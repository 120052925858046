import React from "react";
import classNames from "classnames";

const Button = ({ children, className, ...rest }) => {
  const finalClass = classNames(
    className,
    "leading-normal text-[#fff]"
  );
  return (
    <button
      className={finalClass}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;