import React from "react";
import { styles } from "../../../styles";
import {
  careerHeroSubtitle,
  careerHeroTitle,
  whyChooseIesgData,
  gsap,
  useGSAP,
  ScrollTrigger,
} from "../../../Constants";
import { iesgwhitelogo } from "../../../Assets";
import { IoLogoYoutube } from "react-icons/io";
gsap.registerPlugin(ScrollTrigger);

const CareerHero = () => {
  useGSAP(() => {
    gsap.fromTo(
      "#careerTitle",
      {
        opacity: 0,
        y: 20,
      },
      {
        duration: 1,
        ease: "power2.inOut",
        opacity: 1,
        y: 0,
      }
    );
    gsap.to("#careerOpenings", {
      duration: 1,
      scale: 1.05,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
      scrollTrigger: {
        trigger: "#careerOpenings",
      },
    });
    gsap.fromTo(
      "#careerSubtitle",
      {
        opacity: 0,
        y: 20,
      },
      {
        ease: "power2.inOut",
        opacity: 1,
        duration: 1,
        delay: 0.5,
        y: 0,
      }
    );

    gsap.fromTo(
      "#why-to-choose-cards",
      {
        opacity: 0,
        scale: 0,
      },
      {
        opacity: 1,
        duration: 0.5,
        scale: 1,
        stagger: {
          amount: 1,
          from: "start",
        },
        scrollTrigger: {
          trigger: "#why-to-choose-cards",
        },
      }
    );
  }, []);

  return (
    <div className={`${styles.paddingX} py-[1.2rem] mx-auto`}>
      <div className={`${styles.flexRowWJSIS} my-4`}>
        <span
          id="careerTitle"
          className={`text-start w-full ${styles.heroHeadText} p-0`}
        >
          {careerHeroTitle}&nbsp;
          <img
            src={iesgwhitelogo}
            alt="iesgwhitelogo"
            className=" bg-[#00563C] p-2 rounded-lg w-[6rem]"
          />
        </span>
      </div>

      <p id="careerSubtitle" className={`text-start ${styles.heroSubText}`}>
        {careerHeroSubtitle}
      </p>
      <div className={`${styles.flexRowNWJCIC}`}>
        <a
          href={"#career-openings"}
          className="cursor-pointer"
          id="careerOpenings"
        >
          <div
            className={`${styles.flexRowNWJBIC} border border-black rounded-md my-4 `}
          >
            <p className="p-2 text-[0.8rem]">Current Openings</p>
            <IoLogoYoutube className="text-[#00563C] w-[1.8rem] h-[1.4rem]" />
          </div>
        </a>
      </div>
      <p className={`${styles.sectionHeadText} text-center mt-[1.4rem]`}>
        WHY CHOOSE IESG ?
      </p>
      <div className={`${styles.flexRowWJCIS} gap-[2rem] mt-[2.4rem]`}>
        {whyChooseIesgData?.map((item, index) => (
          <div
            id="why-to-choose-cards"
            key={index}
            className={`w-[16rem] ${styles.flexColNWJCIC}`}
            style={{ boxShadow: "0px 4px 60.8px 0px rgba(0, 0, 0, 0.20)" }}
          >
            <p className="w-[100%] font-medium p-6 text-white text-center bg-[#0F2229] text-[1rem] h-[5.5rem] rounded-md">
              {item?.title}
            </p>
            <p className="w-[90%] h-auto p-6 font-normal text-center text-[0.8rem]">
              {item?.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CareerHero;
