import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { up_arrow_img } from "../../../Assets";
import { ScrollTrigger, gsap, useGSAP } from "../../../Constants";
import { solutionArchitectureData } from "../../../Constants/ServicePageConstants";
import { useGlobalContext } from "../../../context.js/useGlobalContext";
import { styles } from "../../../styles";
import RightPlatform from "./RightPlatform";
gsap.registerPlugin(ScrollTrigger);

const ServiceHero = ({ serviceObj }) => {
  const location = useLocation();
  const [active, setActive] = useState(
    location.hash.slice(1) || serviceObj?.services?.[0]?.id
  );
  const [isScrolling, setIsScrolling] = useState(false);
  const { setIsBookDemoFormOpen } = useGlobalContext();
  const [animationAppliedArray, setAnimationAppliedArray] = useState([]);
  const serviceRef = useRef({});
  const [expandedElement, setExpandedElement] = useState(
    serviceObj?.services?.map((item) => ({ id: item?.id, visible: false }))
  );

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      // if (elem) {
      //   elem.scrollIntoView({ behavior: "smooth", block: "center" });
      // }
      let offset = 150; // Adjust the offset value as needed
      let targetPosition = elem.getBoundingClientRect().top + window.scrollY - offset;
  
      // Scroll to the target position
      window.scrollTo({
          top: targetPosition,
          behavior: "smooth"
      });
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const componentRects = Object.entries(serviceRef.current).map(
        ([key, ref]) => ({
          key,
          rect: ref.getBoundingClientRect(),
        })
      );
      // Find the key of the first component where at least half is visible
      const firstVisibleKey = componentRects.find(
        ({ rect }) =>
          rect.top >= 0 &&
          rect.bottom <= window.innerHeight &&
          rect.bottom - rect.height / 2 <= window.innerHeight
      )?.key;

      if (firstVisibleKey && firstVisibleKey !== active) {
        setActive(firstVisibleKey);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [active]);

  // Used to update the isScrolling state
  window.addEventListener("scroll", () => {
    if (window.scrollY > 20) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  });

  useGSAP(() => {
    !animationAppliedArray?.includes(active) &&
      gsap.fromTo(
        [`#${active}-title`, `#${active}-button`],
        {
          opacity: 0,
          x: -150,
        },
        {
          duration: 1,
          ease: "power2.inOut",
          opacity: 1,
          x: 0,
          scrollTrigger: {
            trigger: `#${active}-title`,
            once: true,
          },
        }
      );
    !animationAppliedArray?.includes(active) &&
      gsap.fromTo(
        `#${active}-points`,
        {
          opacity: 0,
          x: 150,
        },
        {
          duration: 1,
          ease: "power1.inOut",
          opacity: 1,
          x: 0,
          scrollTrigger: {
            trigger: `#${active}-points`,
            once: true,
          },
        }
      );

    setAnimationAppliedArray((prev) => [...prev, active]);
  }, [active]);

  console.log("Serices OBj", serviceObj);

  return (
    <>
      <button
        className={`${
          isScrolling ? "scale-100 duration-500" : "scale-0 duration-500"
        } sticky top-[90%] left-[60%] md:left-[80%] lg:left-[80%] xl:left-[90%] text-white bg-[#00563C] px-[0.8rem] py-[0.4rem] rounded-[0.6rem] font-[500] cursor-pointer z-[999] `}
        onClick={() => window.scrollTo({ top: 0 })}
      >
        Go to Top
      </button>
      <div
        className={`${styles.paddingX} w-full ${styles.flexColNWJCIC} mx-auto relative lg:px-[2rem] gap-[2rem] `}
      >
        <div
          className={`${
            styles.flexRowWJCIS
          } static w-[100vw] lg:sticky top-[0%] z-[999] lg:gap-[1.6rem] bg-[#fff] md:p-[0rem] px-[1rem] ${
            isScrolling && window.innerWidth < 1024
              ? "scale-0 duration-700 "
              : "scale-100 duration-700"
          }`}
        >
          {serviceObj?.services?.map((item, index) => (
            <ScrollLink
              activeClass="active"
              to={item?.id}
              className="cursor-pointer my-[1rem]"
              delay={0}
              offset={-150}
              key={index}
              onClick={() =>
                setAnimationAppliedArray((prev) => [
                  ...prev,
                  ...serviceObj?.services?.map((item) => item?.id),
                ])
              }
            >
              <div
                className={`${
                  window.innerWidth < 1024
                    ? styles.flexColNWJCIC
                    : styles.flexRowNWJSIS
                } ${
                  active === item?.id ? "scale-110" : "scale-100"
                } relative cursor-pointer lg:w-[16rem] xl:min-w-[18rem] w-[13.5rem] mb-[0.9rem] lg:mb-0 hover:scale-110 duration-700 lg:gap-[1rem] lg:bg-white lg:shadow-lg lg:rounded-xl shadow-gray-600 border-1 border-[#10893A] lg:p-[1rem] p-[0.8rem]`}
              >
                <img
                  src={item?.logo}
                  alt={item?.title}
                  className={`h-[4rem] lg:h-[3rem] duration-500 mb-[0.6rem] lg:mb-[0.1rem]`}
                />
                <div className="flex flex-col items-center lg:items-start lg:mt-[0.2rem] ">
                  <span
                    className={`font-semibold text-[0.9rem] lg:text-[0.85rem] `}
                  >
                    {item?.title}
                  </span>
                  <span
                    className={`font-semibold text-[0.7rem] lg:text-[0.71rem] lg:mt-[0.2rem]`}
                  >
                    {item?.subtitle}
                  </span>
                </div>
                {active === item?.id ? (
                  <div
                    style={{
                      background:
                        "linear-gradient(270deg, #B3DC27 0%, #18AF52 100%)",
                    }}
                    className={`h-[0.2rem] w-[8rem] rounded-[0.4rem] mt-[0.4rem] absolute bottom-0 left-1/2 -translate-x-1/2 `}
                  ></div>
                ) : null}
              </div>
            </ScrollLink>
          ))}
        </div>

        <div
          className={`${
            window.innerWidth < 1024
              ? styles.flexColWJCIC
              : styles.flexColNWJCIC
          }  w-[100%] gap-[4rem] lg:gap-[6rem]`}
        >
          {serviceObj?.services?.map((item, index) => (
            <div id={item?.id} key={index} className="w-full">
              <div
                className={`flex ${
                  index % 2 === 0 ? "flex-row" : "flex-row-reverse"
                } justify-center items-center flex-wrap gap-[2rem] sm:gap-[4rem] md:gap-[4rem] lg:gap-[4rem] xl:gap-[6rem] 2xl:gap-[8rem]`}
                ref={(el) => (serviceRef.current[`${item?.id}`] = el)}
              >
                <div className="flex flex-col gap-[2rem] lg:gap-[3rem] ">
                  <img
                    // id={`${item?.id}-image`}
                    src={item?.image}
                    alt={item?.title}
                    className={`w-[18rem] lg:w-[20rem] `}
                  />
                </div>
                <div
                  className={`${styles.flexColNWJSIS} w-[18rem] sm:w-[30rem]`}
                >
                  <div
                    className={`${styles.flexColNWJSIS} gap-[0.4rem]  ${
                      animationAppliedArray?.includes(item?.id)
                        ? "opacity-100"
                        : "opacity-0 "
                    }`}
                    id={`${item?.id}-title`}
                  >
                    <p className=" font-semibold text-[2rem] uppercase ">
                      {item?.title}
                    </p>
                    <p className="font-semibold text-[1.2rem] uppercase  ">
                      {item?.subtitle}
                    </p>
                  </div>
                  {/* <ul
                    className={`p-[1rem] list-disc z-50 ${
                      animationAppliedArray?.includes(item?.id)
                        ? "opacity-100"
                        : "opacity-0 "
                    }`}
                    id={`${item?.id}-points`}
                  >
                    {item?.description?.map((item, index) => (
                      <li
                        key={index}
                        className=" text-[0.9rem] font-normal p-[0.6rem] text-justify leading-normal"
                      >
                        {item}
                      </li>
                    ))}
                  </ul> */}
                  <p
                    key={index}
                    id={`${item?.id}-points`}
                    className={`text-[0.9rem] my-[1rem] font-normal p-[0.6rem] text-justify leading-loose ${
                      animationAppliedArray?.includes(item?.id)
                        ? "opacity-100"
                        : "opacity-0 "
                    }`}
                  >
                    {item?.description}
                  </p>
                  <div className={`flex flex-row justify-center gap-[1rem] `}>
                    <button
                      // to="/about/#book-a-demo"
                      onClick={() => setIsBookDemoFormOpen(true)}
                      className={`w-[7rem] ${styles.flexRowNWJBIC} bg-[#0071E3] rounded-md`}
                    >
                      <p className="p-2 text-[0.8rem] text-[#fff] font-[600] ">
                        Book A Demo
                      </p>
                    </button>
                    {/* <button
                      className={`w-[8rem] ${
                        styles.flexRowNWJBIC
                      } bg-[#fff] rounded-md cursor-pointer ${
                        item?.platform_components?.length > 0
                          ? "flex"
                          : "hidden"
                      } `}
                      onClick={() => {
                        setExpandedElement((prev) =>
                          prev.map((x) =>
                            x.id === `${item?.id}` ? { ...x, visible: true } : x
                          )
                        );
                        setTimeout(() => {
                          const elem = document.getElementById(
                            `${item?.id}-more-content`
                          );
                          if (elem) {
                            const offsetTop =
                              elem.getBoundingClientRect().top + window.scrollY;
                            window.scrollTo({
                              top: offsetTop - 120,
                              behavior: "smooth",
                            });
                          }
                        }, 10);
                      }}
                    >
                      <p className="p-2 text-[1rem] text-[#0071E3] font-[600] ">
                        {`Learn More >`}
                      </p>
                    </button> */}
                  </div>
                </div>
              </div>

              {/* {expandedElement?.find((x) => x?.id === `${item?.id}`)
                ?.visible && (
                
              )} */}
              <div
                id={`${item?.id}-more-content`}
                className={`${
                  window.innerWidth < 1024
                    ? styles.flexColNWJCIC
                    : "flex flex-col justify-center items-start"
                } px-[2rem] lg:px-[4.4rem] my-[2rem] lg:my-[4rem] gap-[2rem] lg:gap-[3rem] `}
              >
                {item?.id === "sustain-sphere" ? (
                  <div
                    className={`${
                      window.innerWidth < 1024
                        ? styles.flexColNWJCIC
                        : "flex flex-col justify-center items-start"
                    } my-[2rem] lg:my-[4rem] gap-[2rem] lg:gap-[4rem] `}
                  >
                    <p className="font-semibold text-[2.2rem] text-center md:text-left ">
                      Solution Architecture
                    </p>
                    <div
                      className={`${
                        window.innerWidth < 426
                          ? styles.flexColNWJCIC
                          : styles.flexRowWJCIC
                      } gap-[2rem]`}
                    >
                      {solutionArchitectureData?.map((item, index) => (
                        <div
                          className={`${
                            window.innerWidth < 426
                              ? styles.flexColNWJCIC
                              : styles.flexRowWJBIC
                          } gap-[2rem] lg:gap-[3rem]`}
                        >
                          <div className={`${styles.flexColNWJCIC} gap-[1rem]`}>
                            <img
                              src={item?.logo}
                              className="w-[4rem] object-contain "
                              alt={item?.label}
                            />
                            <p className="font-semibold text-[1.2rem]">
                              {item?.label}
                            </p>
                          </div>
                          {index !== solutionArchitectureData?.length - 1 ? (
                            <div
                              className={`${styles.flexRowNWJCIC} rotate-90 md:rotate-0 my-[2rem] lg:my-0 `}
                            >
                              <div className="w-[6px] h-[6px] rounded-full bg-black " />
                              <div className="w-[4rem] lg:w-[5rem] h-[2px] bg-black " />
                              <div className="w-[6px] h-[6px] rounded-full bg-black" />
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
                {item?.id === "sustain-sphere" ? (
                  <p className="font-semibold text-[2.2rem] text-center md:text-left ">
                    Platform Components
                  </p>
                ) : null}
                {item?.platform_components?.length > 0 ? (
                  <div className={`${styles.flexColNWJSIS} gap-[2rem]`}>
                    {item?.platform_components?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`${styles.flexColNWJSIS} gap-[1rem] lg:gap-[2rem]`}
                        >
                          <p className="font-semibold text-[1.5rem] text-[#1F9435]">
                            {item?.label}
                          </p>
                          <div className="flex flex-col">
                            <p className="font-normal text-[1rem]">
                              {item?.description}
                            </p>
                            <ul className="list-disc px-[2rem] lg:px-[4rem]">
                              {item?.points?.length > 0
                                ? item?.points?.map((point, index) => (
                                    <li key={index}>
                                      <p className="font-normal text-[1rem] py-[0.5rem]">
                                        {point}
                                      </p>
                                    </li>
                                  ))
                                : null}
                            </ul>
                          </div>
                          <div className="w-[16rem] bg-[#00563C] h-[1px] " />
                        </div>
                      );
                    })}
                  </div>
                ) : null}
                {/* <ScrollLink
                    className={`w-[5rem] flex gap-[0.5rem] px-[0.5rem] py-[0.3rem] bg-[#0071E3] rounded-md cursor-pointer`}
                    onClick={() =>
                      setExpandedElement((prev) =>
                        prev.map((x) =>
                          x.id === `${item?.id}` ? { ...x, visible: false } : x
                        )
                      )
                    }
                    to={`${item?.id}`}
                    offset={-150}
                  >
                    <img
                      src={up_arrow_img}
                      alt=""
                      className="w-[15px] object-contain"
                    />
                    <p className="text-white text-[1rem] font-semibold ">
                      Hide
                    </p>
                  </ScrollLink> */}
              </div>
            </div>
          ))}
        </div>
      </div>
      {serviceObj?.id === "platform-services" ? (
        <RightPlatform services={serviceObj?.services} />
      ) : null}
    </>
  );
};

export default ServiceHero;
