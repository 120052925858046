import React from "react";
import { done_in_about_logo, iesglogo } from "../../../Assets";
import {
  aboutHeroDescription,
  aboutHeroTitle,
  approachDescription,
  approachFieldsData,
  gsap,
  useGSAP,
  ScrollTrigger,
} from "../../../Constants";
import { styles } from "../../../styles";
gsap.registerPlugin(ScrollTrigger);

const AboutHero = () => {
  useGSAP(() => {
    gsap.to("#done-image", {
      rotation: 360,
      // repeat : -1,
      duration: 1,
      ease: "power2.inOut",
      opacity: 1,
      y: 0,
    });
    gsap.fromTo(
      "#green-line",
      {
        width: 0,
      },
      {
        width: "8rem",
        duration: 1,
      }
    );
    gsap.fromTo(
      "#about-iesg-image",
      {
        scale: 0,
      },
      {
        scale: 1,
        duration : 1,
        ease : "power2.inOut"
      }
    );
    gsap.fromTo(
      "#category-title",
      {
        opacity: 0,
        x: -50,
      },
      {
        opacity: 1,
        duration: 1,
        x: 0,
        stagger: {
          amount: 1,
          from: "start",
        },
        scrollTrigger: {
          trigger: "#category-title",
        },
      }
    );
    gsap.fromTo(
      "#description",
      {
        opacity: 0,
        y: -20,
      },
      {
        opacity: 1,
        duration: 1,
        y: 0,
        scrollTrigger: {
          trigger: "#description",
        },
      }
    );
    gsap.fromTo("#approach-description", {
      opacity: 0,
      y: -40,
    },
    {
      opacity: 1,
      duration: 1,
      y: 0,
      scrollTrigger: {
        trigger: "#approach-description",
      },
    })
  }, []);

  return (
    <div
      className={`${styles.paddingX} py-4 w-full ${styles.flexColNWJCIC} mx-auto`}
    >
      <div className={`${styles.flexRowWJCIC}`} id="about-iesg-image">
        <p className="font-bold text-[2rem] px-4 flex flex-wrap justify-center items-center gap-[1rem] ">
          ABOUT
          <img src={iesglogo} alt="iesglogo" className="w-[12rem]" />
        </p>
      </div>
      <p className="text-center my-4 font-bold text-[#4A4A4A] text-[1.4rem]">
        {aboutHeroTitle}
      </p>
      <p id="description" className={`text-center ${styles.heroSubText} w-[70%]`}>
        {aboutHeroDescription}
      </p>
      <div className={`${styles.flexRowWJCIC} gap-[2rem] mt-[4rem]`}>
        {approachFieldsData?.map((item, index) => (
          <div
            className={`${styles.flexColNWJCIC}`}
            id="category-title"
            key={index}
          >
            <p className="uppercase font-medium">{item?.title}</p>
            <div
              id="green-line"
              style={{
                background: "linear-gradient(270deg, #B3DC27 0%, #18AF52 100%)",
              }}
              className="h-[2px] w-[8rem] my-[0.5rem] "
            ></div>
          </div>
        ))}
      </div>
      <img
        id="done-image"
        src={done_in_about_logo}
        alt="done_in_about_logo"
        className="w-[11rem] p-4 mt-[2rem]"
      />
      <p className={`${styles.sectionHeadText} text-center mt-[2.4rem]`}>
        OUR APPROACH
      </p>
      <p id="approach-description" className={`text-justify ${styles.heroSubText} my-4 w-[75%]`}>
        {approachDescription}
      </p>
    </div>
  );
};

export default AboutHero;
