import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import RadialGreenGradient from "../ReusableComponents/RadialGreenGradiant";
import { styles } from "../../styles";
import { ToastContainer } from "react-toastify";
const Layout = () => {
  return (
    <div className={`relative w-[100%]`}>
      <RadialGreenGradient className={"absolute"} />
      <div
        style={{
          // backdropFilter: "blur(10px)",
          // backgroundColor: "rgba(255, 255, 255, 0.5)",
          zIndex: "2000",
        }}
      >
        <Header />
        <div key="mainlayout">
          <ToastContainer
            position="top-center"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="colored"
            className="text-xl z-[99999]"
          />
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  );
  // return (
  //   <div>
  // <Header />
  // <Outlet />
  // <Footer />
  //   </div>
  // )
};

export default Layout;
