import React, { useEffect, useRef, useState } from "react";
import { iesg_website_video } from "../../../Assets";

const Video = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0, // 1.0 means fully visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Video is fully visible, start playing
          setIsPlaying(true);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    observer.observe(videoRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <video
      ref={videoRef}
      playsInline={true}
      className={`my-[6rem] h-[20rem] lg:h-[28rem] `}
      preload="auto"
      // autoPlay={true}
      controls
      autoFocus
    >
        <source src={iesg_website_video} type="video/mp4" />
    </video>
  );
};

export default Video;
