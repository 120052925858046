import React from "react";
import Endorsements from "./Endorsements";
import HomeHero from "./HomeHero";
import IntegrationParterns from "./IntegrationParterns";
import IOT_Solutions from "./IOT_Solutions";
import ValuedBy from "./ValuedBy";
import ProjectSlider from "./ProjectSlider";
import EmpoweringSustainability from "./EmpoweringSustainability";
import RadialGreenGradient from "../../ReusableComponents/RadialGreenGradiant";
import Partners from "./Partners";
import TechnologyPartners from "./TechnologyPartners";
import AllPartners from "./AllPartners";
import ProductCarousel from "./ProductCarousel";
import Dash from "../../ReusableComponents/Dash";

const HomeComponent = () => {
  // return (
  //   <>
  //     <div style={{ zIndex: "1" }}>
  //       <RadialGreenGradient />
  //     </div>
  //     <div
  //       style={{
  //         backgroundColor: "red",
  //         width: "100%",
  //         height: "100%",
  //         position: "absolute",
  //       }}
  //     >
  //       abcd
  //     </div>
  //     <div style={{position:"absolute"}}>
  // <div>
  //   <HomeHero />
  //   {/* <ProjectSlider/> */}
  //   <EmpoweringSustainability />
  //   <IOT_Solutions />
  //   <ValuedBy />
  //   <Endorsements />
  //   <IntegrationParterns />
  // </div>
  //     </div>
  //   </>
  // );
  return (
    <div>
      <RadialGreenGradient
        className={"top-[-4rem] right-0 "}
        style={{
          backgroundImage:
            "radial-gradient(circle 14rem at 100% 50%, rgba(8, 184, 131, 0.4), #0000)",
        }}
      />

      <HomeHero />
      {/* <ProjectSlider/> */}
      <RadialGreenGradient
        className={"top-[40rem] left-0 "}
        style={{
          backgroundImage:
            "radial-gradient(circle 10rem at 0% 50%, rgba(8, 184, 131, 0.4), #0000)",
        }}
      />
      <RadialGreenGradient
        className={"top-[84rem] right-0 "}
        style={{
          backgroundImage:
            "radial-gradient(circle 16rem at 100% 50%, rgba(8, 184, 131, 0.4), #0000)",
        }}
      />
      <EmpoweringSustainability />
      <IOT_Solutions />
      <ProductCarousel />
      <Dash />
      <RadialGreenGradient
        className={"top-[50%] left-0 "}
        style={{
          backgroundImage:
            "radial-gradient(circle 12rem at 0% 50%, rgba(8, 184, 131, 0.4), #0000)",
        }}
      />
      <AllPartners />
      {/* <Endorsements /> */}
      <ValuedBy />
      {/* <IntegrationParterns /> */}
      {/* <Partners/> */}
    </div>
  );
};

export default HomeComponent;
